/***
TABLE CONTENT
 Import Google Fonts
 Include Third Party CSS Library
 GENERAL
 HEADER
 Offcanvas Sidebar
 Search form
 Main header
 Mega menu
 Header layouts
 Page layouts
 Pagination
 Breadcrumb
 CATEGORY PAGES
 SINGLE POST
 Author box
 comment
 Post share social
 WP STYPE
 Custom amine
 
***/
@font-face {
  font-family: "careem";
  src: url(../fonts/careem.otf)
}
*{
  font-family: "careem";
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");
/*import vendors*/
@import url(vendors/normalize.css);
@import url(vendors/bootstrap.min.css);
@import url(vendors/fontawesome-all.min.css);
@import url(vendors/wowy-font.css);
/*import plugins*/
@import url(plugins/jquery-ui.css);
@import url(plugins/magnific-popup.css);
@import url(plugins/animate.css);
@import url(plugins/select2.min.css);
@import url(plugins/slick.css);
@import url(plugins/perfect-scrollbar.css);
/*RESET*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

thead {
  font-weight: 600;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  outline: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

/*--- Common Classes---------------------*/
::-webkit-input-placeholder {
  color: #838383;
}
:-ms-input-placeholder {
  color: #838383;
}
::-ms-input-placeholder {
  color: #838383;
}
::placeholder {
  color: #838383;
}

.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.position-relative {
  position: relative;
}

.height-100vh {
  height: 100vh !important;
}

*:focus,
select:focus,
.custom-select:focus,
button:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  outline: none !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.bg-grey-9 {
  background-color: #f4f5f9;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-20 {
  border-radius: 20px;
}

.img-hover-scale img {
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  transition: transform .5s,-webkit-transform .5s;
}

.img-hover-scale:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  transition: transform .5s,-webkit-transform .5s;
}

.hover-up {
  -webkit-transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.hover-up:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  -webkit-transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.text-brand {
  color: #037ee5 !important;
}

.text-primary {
  color: #3f81eb !important;
}

.text-warning {
  color: #ffb300 !important;
}

.text-danger {
  color: #ff3551 !important;
}

.text-success {
  color: #3ed092 !important;
}

.text-info {
  color: #18a1b7 !important;
}

.text-grey-4 {
  color: #90908e !important;
}

.text-muted {
  color: #8e8e90 !important;
}

.text-grey-5, .text-grey-5 a, .text-hover-grey-5:hover {
  color: #c1c7cf !important;
}

.bg-brand {
  background-color: #46a3bf !important;
}

.bg-primary {
  background-color: #3f81eb !important;
}

.bg-warning {
  background-color: #ffb300 !important;
}

.bg-danger {
  background-color: #ff3551 !important;
}

.bg-success {
  background-color: #3ed092 !important;
}

.bg-info {
  background-color: #18a1b7 !important;
}

.bg-grey-4 {
  background-color: #90908e !important;
}

.flex-horizontal-center {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
}
.Checks0 ,.Checks1 ,.Checks2 ,.Checks3,.Checks4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 10%;
  margin: 2px;
  color: #008dde;
  background-color: #fff;
  border: 3px solid #008dde;
  cursor: pointer;
}
.Checks0a,.Checks1a,.Checks2a,.Checks3a,.Checks4a{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 10%;
  margin: 2px;
  color: #fff;
  background-color: #008dde;
  border: 3px solid #008dde;
  cursor: pointer;
}
#checked{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 10%;
  margin: 2px;
  color: #fff;
  background-color: #008dde;
  border: 3px solid #008dde;
 }
.CheckColor1a{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  background-color: black;
  border: 3px solid rgb(40, 245, 68);
  cursor: pointer;
}
.CheckColor1{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  background-color: black;
  border: 1px solid #000;
  cursor: pointer;
}
.CheckColor2a{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  background-color: white;
  border: 3px solid rgb(40, 245, 68);
  cursor: pointer;
}
.CheckColor2{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  background-color: white;
  border: 1px solid #000;
  cursor: pointer;
}
.h-500 {
  min-height: 500px;
}

.border {
  border: 1px solid #eee !important;
}

.box-shadow-outer-6 {
  -webkit-box-shadow: 0 0 11px 0 rgba(78, 42, 222, 0.03), 0 8px 22px 0 rgba(78, 42, 222, 0.09);
  box-shadow: 0 0 11px 0 rgba(78, 42, 222, 0.03), 0 8px 22px 0 rgba(78, 42, 222, 0.09);
}

.box-shadow-outer-6:hover {
  -webkit-box-shadow: 0 0 15px 0 rgba(78, 42, 222, 0.03), 0 8px 22px 0 rgba(78, 42, 222, 0.09);
  box-shadow: 0 0 15px 0 rgba(78, 42, 222, 0.03), 0 8px 22px 0 rgba(78, 42, 222, 0.09);
}

.box-shadow-outer-7 {
  -webkit-box-shadow: 0 0 11px 0 rgba(78, 42, 222, 0.03), 0 8px 16px 0 rgba(78, 42, 222, 0.08);
  box-shadow: 0 0 11px 0 rgba(78, 42, 222, 0.03), 0 8px 16px 0 rgba(78, 42, 222, 0.08);
}

.box-shadow-outer-7:hover {
  -webkit-box-shadow: 0 0 14px 0 rgba(78, 42, 222, 0.03), 0 8px 18px 0 rgba(78, 42, 222, 0.09);
  box-shadow: 0 0 14px 0 rgba(78, 42, 222, 0.03), 0 8px 18px 0 rgba(78, 42, 222, 0.09);
}

.box-shadow-outer-3, .box-hover-shadow-outer-3:hover {
  -webkit-box-shadow: 0 5px 16px 0 rgba(118, 126, 173, 0.09);
          box-shadow: 0 5px 16px 0 rgba(118, 126, 173, 0.09);
}

/*****************************
*********  BORDER  *****
******************************/
.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-solid {
  border-style: solid !important;
}

.border-double {
  border-style: double !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-brand {
  border-color: #46a3bf !important;
}

.border-muted {
  border-color: #f7f8f9;
}

.section-border {
  border-top: 1px solid #e6e9ec;
  border-bottom: 1px solid #e6e9ec;
}

.border-color-1 {
  border-color: #e0dede;
}

a,
button,
img,
input,
span,
h4 {
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
}

@-webkit-keyframes slideleft {
  10% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    right: 0;
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  90% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    right: 100%;
  }
}

@keyframes slideleft {
  10% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    right: 0;
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  90% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    right: 100%;
  }
}

[data-loader='spinner'] {
  width: 35px;
  height: 35px;
  display: inline-block;
  -webkit-animation: spinner 1.2s infinite ease-in-out;
  animation: spinner 1.2s infinite ease-in-out;
  -webkit-box-shadow: 0 0 10px #fff;
          box-shadow: 0 0 10px #fff;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0);
    transform: perspective(120px) rotateX(-180deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}

@-webkit-keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.8);
            box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.8);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}

@keyframes shadow-pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.8);
            box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.8);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes shadow-pulse-big {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.1);
            box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.1);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

@keyframes shadow-pulse-big {
  0% {
    -webkit-box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.1);
            box-shadow: 0 0 0 0px rgba(239, 63, 72, 0.1);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 20%, 0);
            transform: translate3d(0, 20%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes jump {
  0% {
    -webkit-transform: translate3d(0, 20%, 0);
            transform: translate3d(0, 20%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.jump {
  -webkit-transform-origin: 0;
          transform-origin: 0;
  -webkit-animation: jump .5s linear alternate infinite;
          animation: jump .5s linear alternate infinite;
}

/*TYPOGRAPHY*/
body {
  color: #4f5d77;
  font-family:"careem";
  font-size: 13px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "careem";
  color: #222222;
  font-weight: 600;
  line-height: 1.2;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  color: #4f5d77;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  text-decoration: none;
  cursor: pointer;
}

b {
  font-weight: 500;
}

strong,
.fw-600 {
  font-weight: 600;
}

.fw-900 {
  font-weight: 900;
}

.fw-300 {
  font-weight: 300;
}

.section-title {
  font-weight: 600;
}

.section-title.style-1 {
  position: relative;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.section-title.style-1::after {
  content: "";
  width: 100px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #46a3bf;
}

.section-title span {
  color: #46a3bf;
}

h3.section-title {
  font-size: 22px;
}

h5.widget-title {
  font-size: 18px;
  font-weight: 600;
}

.text-body {
  color: #4f5d77 !important;
}

.font-xxs {
  font-size: 10px;
}

.font-xs {
  font-size: 12px;
}

.font-sm {
  font-size: 13px;
}

.font-md {
  font-size: 14px;
}

.font-lg {
  font-size: 15px;
}

.font-xl {
  font-size: 16px;
}

.font-xxl {
  font-size: 58px;
}

.text-style-1 {
  position: relative;
}

.text-style-1::after {
  content: "";
  background-color: #ffdb85;
  height: 20%;
  width: 110%;
  display: block;
  position: absolute;
  bottom: 20%;
  left: -5%;
  z-index: -1;
  opacity: 0.8;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  transition: transform .5s,-webkit-transform .5s;
}

.text-style-1:hover::after {
  height: 30%;
  -webkit-transition: -webkit-transform .5s;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  transition: transform .5s,-webkit-transform .5s;
}

/*COMPONENTS -> BUTTONS*/
.btn-default {
  color: #fff;
  background-color: #46a3bf;
  border-radius: 50px;
  padding: 13px 28px;
}

.btn-default i {
  font-weight: 400;
  font-size: 12px;
  margin-left: 10px;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}

.btn-default:hover i {
  margin-left: 15px;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}

.btn-lg {
  padding: 13px 28px;
  font-size: 16px;
}

.btn-sm {
  padding: 8px 18px !important;
  font-size: 12px;
}

.btn-md {
  padding: 10px 24px !important;
  font-size: 12px;
}

.btn-outline {
  background-color: transparent !important;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.btn:hover {
  color: white;
}

.btn-shadow-brand {
  -webkit-box-shadow: 0 2px 6px 0 rgba(88, 151, 251, 0.16);
          box-shadow: 0 2px 6px 0 rgba(88, 151, 251, 0.16);
  border: 1px solid #f7f8f9;
}

.btn-shadow-brand:hover {
  border: 1px solid #46a3bf;
}

.btn-brand {
  background-color: #46a3bf;
  border-color: #46a3bf;
}

button.submit, button[type='submit'] {
  font-size: 15px;
  font-weight: 500;
  padding: 12px 40px;
  color: #ffffff;
  border: none;
  background-color: #46a3bf;
  border-radius: 50px;
}

button.submit:hover, button[type='submit']:hover {
  background-color: #3f81eb !important;
}

.btn-brand:hover {
  background-color: #3f81eb !important;
}

.btn-login {
  font-weight: 13px;
}

.btn-login .btn {
  min-width: unset;
}

.btn-login li {
  margin: 0px 5px 0;
  display: inline-block;
}

.btn-login li a {
  border-radius: 5px;
  padding: 15px 25px;
  color: #fff;
  display: block;
  line-height: 1;
  text-transform: none;
  letter-spacing: 0;
  font-size: 14px;
}

.btn, .button {
  display: inline-block;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 54px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #46a3bf;
  text-transform: uppercase;
  background: #46a3bf;
  cursor: pointer;
  -webkit-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

.btn:hover, .button:hover {
  background: #3f81eb;
}

.btn.btn-sm, .button.btn-sm {
  padding: 8px 18px;
  font-size: 12px;
  text-transform: none;
  line-height: 1.8;
}

.btn.btn-rounded, .button.btn-rounded {
  border-radius: 50px;
}

.btn.btn-secondary, .button.btn-secondary {
  background-color: #41506b;
  border-color: #41506b;
}

.btn.btn-facebook, .button.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
}

.btn.btn-google, .button.btn-google {
  background: #d85040;
  border-color: #d85040;
}

/*COMPONENTS -> FORM*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
}

input {
  background: #f4f5f9;
  border: 2px solid #f4f5f9;
  border-radius: 50px;
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  font-size: 13px;
  color: #4f5d77;
  width: 100%;
}

input:focus {
  background: transparent;
}

input.square {
  border-radius: 0;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #f4f5f9;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 13px;
  color: #4f5d77;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 13px;
}

textarea {
  resize: vertical;
  background: #f4f5f9;
  border: 2px solid #f4f5f9;
  padding: 10px 10px 10px 20px;
  width: 100%;
  font-size: 13px;
  color: #4f5d77;
  min-height: 200px;
  border-radius: 20px;
}

textarea:focus {
  background: transparent;
  outline: none;
}

.select2-container--default .select2-selection--single {
  background-color: #f9f9f9;
  border: 2px solid #eee;
  border-radius: 30px 0 0 30px !important;
  border-right: 0;
  height: 52px;
  padding-left: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 50px;
  font-size: 14px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 14px;
  right: 10px;
  width: 20px;
}

.custom_select {
  position: relative;
  width: 100%;
}

.custom_select .nice-select {
  width: 100%;
  margin-bottom: 1rem;
}

.custom_select .select2-container--default .select2-selection--single {
  background-color: #f4f5f9;
  border: 2px solid #f4f5f9;
  border-radius: 50px !important;
  height: 45px;
  line-height: 45px;
  padding-left: 20px;
  font-size: 13px;
  color: #4f5d77;
}

.custom_select .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
  font-size: 13px;
  color: #4f5d77;
  padding-left: 0;
}

.custom_select .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 9px;
  right: 14px;
}

.select2-dropdown {
  border: 0;
  border-radius: 5px;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
          box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  padding: 15px;
  min-width: 220px;
}

.select2-dropdown .select2-search--dropdown {
  padding: 0;
}

.select2-dropdown .select2-search--dropdown .select2-search__field {
  border: 1px solid #eee;
  margin-bottom: 15px;
  border-radius: 50px;
  height: 40px;
  padding-left: 20px;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.select2-results__options::-webkit-scrollbar {
  width: 16px;
  background-clip: padding-box;
}

.select2-results__options::-webkit-scrollbar-track {
  background-color: #F4F4F4;
  height: 8px;
  background-clip: padding-box;
  border-right: 10px solid rgba(0, 0, 0, 0);
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}

.select2-results__options::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #d1d1d1;
  border-right: 10px solid rgba(0, 0, 0, 0);
  border-top: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid rgba(0, 0, 0, 0);
}

.select2-results__options::-webkit-scrollbar-button {
  display: none;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #ececec;
  color: unset;
}

/*contact form*/
.contact-from-area .contact-form-style button {
  font-size: 15px;
  font-weight: 500;
  padding: 12px 40px;
  color: #ffffff;
  border: none;
  background-color: #46a3bf;
  width: 100%;
  border-radius: 50px;
}

.contact-from-area .contact-form-style button.submit-auto-width {
  width: auto;
  display: inline-block;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group input {
  background: #f4f5f9;
  border: 2px solid #f4f5f9;
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  font-size: 13px;
  color: #4f5d77;
  width: 100%;
}

.form-group input:focus {
  background: transparent;
  border-color: #f4f5f9;
}

label {
  margin-bottom: 5px;
}

.custome-radio .form-check-label,
.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
}

.custome-checkbox .form-check-label {
  position: relative;
  cursor: pointer;
  color: #687188;
  padding: 0;
  vertical-align: middle;
}

.custome-checkbox .form-check-label::before {
  content: "";
  border: 2px solid #ced4da;
  height: 17px;
  width: 17px;
  margin: 0px 8px 0 0;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
}

.custome-checkbox .form-check-label span {
  vertical-align: middle;
}

.custome-checkbox input[type="checkbox"]:checked + .form-check-label::after {
  opacity: 1;
}

.custome-checkbox input[type="checkbox"] + .form-check-label::after {
  content: "";
  width: 11px;
  position: absolute;
  top: 50%;
  left: 3px;
  opacity: 0;
  height: 6px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: translateY(-65%) rotate(-45deg);
  transform: translateY(-65%) rotate(-45deg);
}

.custome-radio .form-check-input,
.custome-checkbox .form-check-input {
  display: none;
}

.login_footer {
  margin-bottom: 20px;
  margin-top: 5px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.custome-checkbox input[type="checkbox"]:checked + .form-check-label::before {
  background-color: #46a3bf;
  border-color: #46a3bf;
}

.custome-checkbox input[type="checkbox"]:checked + .form-check-label::after {
  opacity: 1;
}

.divider-text-center {
  text-align: center;
  position: relative;
}

.divider-text-center::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid #ddd;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.divider-text-center span {
  background-color: #fff;
  padding: 0 15px;
  position: relative;
  text-transform: uppercase;
}

.login_wrap {
  border-color: #f7f8f9;
  border-style: solid;
  border-width: 2px;
}

/*comment*/
.comments-area {
  background: transparent;
  border-top: 1px solid #eee;
  padding: 45px 0;
  margin-top: 50px;
}

.comments-area h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

.comments-area a {
  color: #2a2a2a;
}

.comments-area .comment-list {
  padding-bottom: 48px;
}

.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

.comments-area .comment-list .single-comment {
  padding: 0 0 15px 0;
  margin: 0 0 15px 0;
}

.comments-area .comment-list .single-comment:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.comments-area .thumb {
  margin-right: 20px;
}

.comments-area .thumb img {
  width: 70px;
  border-radius: 50%;
}

.comments-area .date {
  font-size: 14px;
  color: #999999;
  margin-bottom: 0;
  margin-left: 20px;
}

.comments-area .comment {
  margin-bottom: 10px;
  color: #777777;
  font-size: 15px;
}

.comments-area .btn-reply {
  background-color: transparent;
  color: #888888;
  padding: 5px 18px;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

.comments-area h4 {
  margin-bottom: 35px;
  color: #2a2a2a;
  font-size: 18px;
}

.comment-form .email {
  padding-right: 0px;
}

.form-control {
  border: 1px solid #f0e9ff;
  border-radius: 5px;
  height: 48px;
  padding-left: 18px;
  font-size: 14px;
  background: transparent;
}

.comment-form {
  border-top: 1px solid #eee;
  padding-top: 45px;
  margin-bottom: 20px;
}

.comment-form .form-group {
  margin-bottom: 20px;
}

.comment-form textarea {
  resize: vertical;
  background: #f4f5f9;
  border: 2px solid #f4f5f9;
  padding: 10px 10px 10px 20px;
  width: 100%;
  font-size: 13px;
  color: #4f5d77;
  min-height: 200px;
  border-radius: 20px;
}

.comment-form textarea:focus {
  background: transparent;
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #f4f5f9;
}

.comment-form input {
  background: #f4f5f9;
  border: 2px solid #f4f5f9;
  border-radius: 50px;
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  font-size: 13px;
  color: #4f5d77;
  width: 100%;
}

.comment-form button {
  font-size: 15px;
  font-weight: 500;
  padding: 12px 40px;
  color: #ffffff;
  border: none;
  background-color: #46a3bf;
  border-radius: 50px;
}

.comment-form button:hover {
  background-color: #3f81eb !important;
  border: none !important;
}

.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  font-weight: 300;
  color: #999999;
  color: #777777;
}

.form-control:-ms-input-placeholder {
  font-weight: 300;
  color: #999999;
  color: #777777;
}

.form-control::-ms-input-placeholder {
  font-weight: 300;
  color: #999999;
  color: #777777;
}

.form-control::placeholder {
  font-weight: 300;
  color: #999999;
  color: #777777;
}

.nice-select .list {
  width: 100%;
}

.button-contactForm {
  background: #46a3bf;
  color: #fff;
  border-color: #46a3bf;
  padding: 12px 25px;
}

.search-form form {
  position: relative;
}

.search-form form input {
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-form form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  border: none;
  font-size: 14px;
  height: 100%;
  padding: 0 14px;
  background-color: transparent;
  color: #242424;
}

/*COMPONENTS -> SLIDER*/
.single-animation-wrap.slick-active .slider-animated-1 h1 {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h2 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h3 {
  -webkit-animation-delay: 1.0s;
  animation-delay: 1.0s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h4 {
  -webkit-animation-delay: 1.0s;
  animation-delay: 1.0s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 span {
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 p {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 a.btn {
  -webkit-animation-delay: 2.0s;
  animation-delay: 2.0s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1.slider-product-price {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

.single-animation-wrap.slick-active .slider-animated-1 .single-slider-img img {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .slider-product-offer-wrap,
.single-animation-wrap.slick-active .slider-animated-1 .slider-product-offer-wrap-2 {
  -webkit-animation-delay: 1.0s;
  animation-delay: 1.0s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.hero-slider-1 {
  height: 620px;
}

.hero-slider-1 img {
  max-height: 620px;
}

.hero-slider-1.style-2 {
  height: 600px;
}

.hero-slider-1.style-2 .slider-1-height-2 {
  height: 600px;
  position: relative;
}

.hero-slider-1.style-2 .hero-slider-content-2 {
  padding-left: 300px;
  padding-top: 100px;
}

.hero-slider-1.style-2 .single-slider-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.hero-slider-1.style-2 .single-slider-img img {
  max-width: 600px;
}

.hero-slider-1.style-3 {
  position: relative;
  height: unset;
}

.hero-slider-1.style-3 .slider-1-height-3 {
  height: 542px;
}

.hero-slider-1.style-3 .slider-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}

.hero-slider-1.style-3 .hero-slider-content-2 {
  position: absolute;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
  padding-left: 100px;
}

.hero-slider-1.style-3 .hero-slider-content-2 h1 {
  font-size: 50px;
}

.hero-slider-1.style-3 .hero-slider-content-2 h2 {
  font-size: 38px;
  line-height: 1.2;
}

.hero-slider-1.style-3 .hero-slider-content-2 h4 {
  font-size: 18px;
  margin: 0 0 7px 0;
}

.hero-slider-1.style-3 .hero-slider-content-2 p {
  font-size: 14px;
}

.hero-slider-1.style-3 .hero-slider-content-2 .btn-default {
  color: #fff;
  background-color: #ffb300;
  border-radius: 50px;
  padding: 10px 19px;
  font-size: 13px;
}

.hero-slider-content-2 h1 {
  font-size: 58px;
  line-height: 64px;
  margin: 0 0 0px;
}

.hero-slider-content-2 h2 {
  font-size: 46px;
  line-height: 54px;
  margin: 0 0 0px;
}

.hero-slider-content-2 h3 {
  font-size: 34px;
  font-weight: 700;
  margin: 10px 0 15px;
  line-height: 1;
}

.hero-slider-content-2 h4 {
  font-size: 24px;
  margin: 0 0 18px;
}

.hero-slider-content-2 p {
  font-size: 18px;
  line-height: 36px;
  margin: 7px 0 22px;
  width: 74%;
}

.hero-slider-content-2 span {
  display: block;
  font-size: 15px;
  line-height: 1;
}

.single-slider-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
}

.dot-style-1 ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dot-style-1 ul li {
  margin: 0 7px;
}

.dot-style-1 ul li button {
  width: 10px;
  height: 10px;
  border-radius: 30px;
  border: 1px solid;
  padding: 0;
  font-size: 0px;
  border-color: #c1c1c1;
  background: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.dot-style-1 ul li button:hover {
  background: #c1c1c1;
}

.dot-style-1 ul li.slick-active button {
  background: #46a3bf;
  width: 20px;
  border-color: #46a3bf;
}

.dot-style-1.dot-style-1-position-1 ul {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 35px;
}

.dot-style-1.dot-style-1-center ul {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dot-style-1.dot-style-1-mt1 ul {
  margin-top: 30px;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 2;
  width: 100%;
}

.slider-arrow .slider-btn {
  cursor: pointer;
  background: #fff;
  width: 55px;
  height: 55px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%;
  border: 1px solid #dcdeed;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  text-align: center;
  line-height: 55px;
  font-size: 20px;
  color: #46a3bf;
}

.slider-arrow .slider-btn.slider-prev {
  left: 20px;
}

.slider-arrow .slider-btn.slider-prev i {
  margin-right: 4px;
}

.slider-arrow .slider-btn.slider-next {
  right: 20px;
}

.slider-arrow .slider-btn.slider-next i {
  margin-left: 4px;
}

.slider-arrow .slider-btn:hover {
  background-color: #46a3bf;
  color: #fff;
  border-color: #46a3bf;
}

.slider-arrow.slider-arrow-2 .slider-btn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.slider-arrow.slider-arrow-3 .slider-btn {
  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 12px;
  margin-top: -15px;
}

.slider-arrow.style-3 .slider-btn {
  width: 40px;
  height: 40px;
  border: 1px solid #dcdeed;
  line-height: 40px;
  font-size: 12px;
  margin-top: -20px;
}

.home-slide-cover {
  position: relative;
  padding: 0 30px;
}

.home-slide-cover .slider-arrow {
  left: 0;
}

.home-slide-cover .hero-slider-content-2 {
  padding-left: 50px;
}

/*Carausel*/
.carausel-6-columns-cover .carausel-6-columns {
  overflow: hidden;
  margin: 0 -15px;
}

.carausel-6-columns-cover .carausel-6-columns .card-1 {
  margin-right: 15px;
  margin-left: 15px;
}

.carausel-6-columns-cover .slider-arrow {
  top: -60px;
}

.carausel-6-columns-cover .slider-arrow .slider-btn.slider-next {
  right: 0;
}

.carausel-6-columns-cover .slider-arrow .slider-btn.slider-prev {
  right: 50px;
  left: unset;
}

.carausel-6-columns-cover.arrow-center .slider-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-top: -50px;
}

.carausel-6-columns-cover.arrow-center .slider-arrow .slider-btn.slider-next {
  right: -30px;
}

.carausel-6-columns-cover.arrow-center .slider-arrow .slider-btn.slider-prev {
  left: -30px;
}

.carausel-6-columns-cover.arrow-center .slider-arrow.slider-arrow-3 {
  margin-top: 0;
}

.carausel-4-columns-cover .carausel-4-columns {
  overflow: hidden;
  margin: 0 -14px;
}

.carausel-4-columns-cover .carausel-4-columns .product-cart-wrap {
  margin-right: 7px;
  margin-left: 7px;
}

.carausel-4-columns-cover .carausel-4-columns-arrow {
  margin-top: -100px;
}

/*COMPONENTS -> CARD*/
.card-1 {
  position: relative;
  background: #fff;
  text-align: center;
  border: 1px solid #e9ebf0;
}

.card-1 h5 {
  text-transform: uppercase;
  font-weight: 400;
}

.card-1 h5 a {
  color: #4f5d77;
}

.card-1:hover a {
  color: #46a3bf;
}

.hero-card {
  width: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.hero-card .hero-card-icon {
  width: 65px;
  height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.hero-card .hero-card-icon.icon-left {
  width: 135px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.hero-card .hero-card-icon.icon-left-2 {
  width: 265px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.hero-card .hero-card-icon i {
  font-size: 25px;
  color: #6143f7;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eee;
  border-radius: .25rem;
}

.card .card-header {
  padding: 1rem;
  margin-bottom: 0;
  background-color: #f7f8f9;
  border-bottom: 1px solid #eee;
}

/*COMPONENTS -> TABS*/
.nav-tabs {
  margin-bottom: 30px;
}

.nav-tabs .nav-link {
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0 !important;
  margin: 0 25px;
  color: #90908e;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #46a3bf;
  color: #46a3bf;
  background-color: transparent;
}

.nav-tabs .nav-link:first-child {
  margin-left: 0 !important;
}

.nav-tabs.no-border {
  border: none;
}

.nav-tabs.right .nav-item:last-child .nav-link {
  margin-right: 0;
}

.nav.right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.dashboard-menu {
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
}

.dashboard-menu ul {
  padding: 0;
  margin: 0;
}

.dashboard-menu ul li {
  position: relative;
}

.dashboard-menu ul li a {
  font-size: 14px;
  color: #242424;
  padding: 15px 30px;
}

.dashboard-menu ul li a i {
  color: #90908e;
  font-size: 12px;
}

.dashboard-menu ul li a.active {
  color: #fff;
  background-color: #46a3bf;
}

.dashboard-menu ul li a.active i {
  color: #fff;
}

.dashboard-menu ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

/*COMPONENTS -> MISC*/
/*Countdown*/
.deals-countdown .countdown-section {
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  padding: .9rem 0 .3rem;
  margin-left: .8rem;
  margin-right: .8rem;
  background-color: #46a3bf;
  border-radius: .3rem;
  border: none;
  margin-bottom: 2rem;
}

.deals-countdown .countdown-section .countdown-amount {
  display: inline-block;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 15px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.deals-countdown .countdown-section .countdown-period {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -20px;
  display: block;
  font-weight: 400;
  color: #90908e;
  text-transform: uppercase;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deals-countdown .countdown-section:not(:last-child)::after {
  color: #111111;
  content: ':';
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  position: absolute;
  left: 100%;
  margin-left: 12px;
  margin-top: -1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.img-grey-hover {
  opacity: .5;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.img-grey-hover:hover {
  -webkit-filter: none;
          filter: none;
  opacity: 1;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.brand-logo img {
  max-height: 50px;
  width: auto;
  display: inline-block;
}

/*Heading tab*/
.heading-tab {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-align: left;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/*page loading*/
.preloader {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: .6s;
  transition: .6s;
  margin: 0 auto;
}

.preloader img.jump {
  max-height: 100px;
}

/*custom amine*/
.loader,
.bar {
  width: 100px;
  height: 20px;
}

.bar {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.bar::before,
.bar::after {
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background: #3fa9f5;
  opacity: 0;
  border-radius: 10px;
  -webkit-animation: slideleft 3s ease-in-out infinite;
          animation: slideleft 3s ease-in-out infinite;
}

.bar1::before {
  -webkit-animation-delay: 0.00s;
          animation-delay: 0.00s;
}

.bar1::after {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.bar2::before {
  -webkit-animation-delay: 0.60s;
          animation-delay: 0.60s;
}

.bar2::after {
  -webkit-animation-delay: 0.90s;
          animation-delay: 0.90s;
}

.bar3::before {
  -webkit-animation-delay: 1.20s;
          animation-delay: 1.20s;
}

.bar3::after {
  -webkit-animation-delay: 1.50s;
          animation-delay: 1.50s;
}

/*page header*/
.page-header .page-title {
  font-weight: 900;
  font-size: 4rem;
}

.page-header.breadcrumb-wrap {
  padding: 20px;
  background-color: #f7f8f9;
}

.page-header .breadcrumb {
  display: inline-block;
  padding: 0;
  text-transform: capitalize;
  color: #6e6e6e;
  font-size: 0.875rem;
  background: none;
  margin: 0;
  border-radius: 0;
}

.page-header .breadcrumb span {
  position: relative;
  text-align: center;
  padding: 0 10px;
}

.page-header .breadcrumb span::before {
  content: "\f105";
  font-family: "careem";
  display: inline-block;
  font-size: 9px;
}

/*****************************
*********  SOCIAL NETWORKS  **********
******************************/
.text-center.social-icons ul {
  display: inline-block;
}

.social-icons li {
  float: left;
  list-style: none;
}

.social-icons li a {
  float: left;
  height: 32px;
  width: 32px;
  line-height: 32px;
  font-size: 16px;
  text-align: center;
  margin: 0 4px 4px 0;
  border-radius: 4px;
  border: 0;
  background: 0 0;
  color: #333;
  overflow: hidden;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.dark .social-icons li a {
  color: #fff;
}

.social-icons.social-icons-colored a,
.social-icons.social-icons-colored-hover a:hover {
  color: #fff !important;
  border: 0;
}

.social-icons.social-icons-colored .social-rss a,
.social-icons.social-icons-colored-hover .social-rss a:hover,
.social-icons.social-icons-colored .social-snapchat a,
.social-icons.social-icons-colored-hover .social-snapchat a:hover {
  background-color: #faa33d;
}

.social-icons.social-icons-colored .social-facebook a,
.social-icons.social-icons-colored-hover .social-facebook a:hover {
  background-color: #5d82d1;
}

.social-icons.social-icons-colored .social-twitter a,
.social-icons.social-icons-colored-hover .social-twitter a:hover {
  background-color: #40bff5;
}

.social-icons.social-icons-colored .social-vimeo a,
.social-icons.social-icons-colored-hover .social-vimeo a:hover {
  background-color: #35c6ea;
}

.social-icons.social-icons-colored .social-myspace a,
.social-icons.social-icons-colored-hover .social-myspace a:hover {
  background-color: #008dde;
}

.social-icons.social-icons-colored .social-youtube a,
.social-icons.social-icons-colored-hover .social-youtube a:hover {
  background-color: #ef4e41;
}

.social-icons.social-icons-colored .social-instagram a,
.social-icons.social-icons-colored-hover .social-instagram a:hover {
  background-color: #e53d00;
}

.social-icons.social-icons-colored .social-gplus a,
.social-icons.social-icons-colored-hover .social-gplus a:hover {
  background-color: #d68400;
}

.social-icons.social-icons-colored .social-stumbleupon a,
.social-icons.social-icons-colored-hover .social-stumbleupon a:hover {
  background-color: #ff5c30;
}

.social-icons.social-icons-colored .social-lastfm a,
.social-icons.social-icons-colored-hover .social-lastfm a:hover {
  background-color: #f34320;
}

.social-icons.social-icons-colored .social-pinterest a,
.social-icons.social-icons-colored-hover .social-pinterest a:hover {
  background-color: #e13138;
}

.social-icons.social-icons-colored .social-google a,
.social-icons.social-icons-colored-hover .social-google a:hover {
  background-color: #eb5e4c;
}

.social-icons.social-icons-colored .social-evernote a,
.social-icons.social-icons-colored-hover .social-evernote a:hover {
  background-color: #9acf4f;
}

.social-icons.social-icons-colored .social-dribbble a,
.social-icons.social-icons-colored-hover .social-dribbble a:hover {
  background-color: #f7659c;
}

.social-icons.social-icons-colored .social-skype a,
.social-icons.social-icons-colored-hover .social-skype a:hover {
  background-color: #13c1f3;
}

.social-icons.social-icons-colored .social-forrst a,
.social-icons.social-icons-colored-hover .social-forrst a:hover {
  background-color: #45ad76;
}

.social-icons.social-icons-colored .social-linkedin a,
.social-icons.social-icons-colored-hover .social-linkedin a:hover {
  background-color: #238cc8;
}

.social-icons.social-icons-colored .social-wordpress a,
.social-icons.social-icons-colored-hover .social-wordpress a:hover {
  background-color: #2592c3;
}

.social-icons.social-icons-colored .social-grooveshark a,
.social-icons.social-icons-colored-hover .social-grooveshark a:hover {
  background-color: #ffb21d;
}

.social-icons.social-icons-colored .social-delicious a,
.social-icons.social-icons-colored-hover .social-delicious a:hover {
  background-color: #377bda;
}

.social-icons.social-icons-colored .social-behance a,
.social-icons.social-icons-colored-hover .social-behance a:hover {
  background-color: #1879fd;
}

.social-icons.social-icons-colored .social-dropbox a,
.social-icons.social-icons-colored-hover .social-dropbox a:hover {
  background-color: #17a3eb;
}

.social-icons.social-icons-colored .social-soundcloud a,
.social-icons.social-icons-colored-hover .social-soundcloud a:hover {
  background-color: #ff7e30;
}

.social-icons.social-icons-colored .social-deviantart a,
.social-icons.social-icons-colored-hover .social-deviantart a:hover {
  background-color: #6a8a7b;
}

.social-icons.social-icons-colored .social-yahoo a,
.social-icons.social-icons-colored-hover .social-yahoo a:hover {
  background-color: #ab47ac;
}

.social-icons.social-icons-colored .social-flickr a,
.social-icons.social-icons-colored-hover .social-flickr a:hover {
  background-color: #ff48a3;
}

.social-icons.social-icons-colored .social-digg a,
.social-icons.social-icons-colored-hover .social-digg a:hover {
  background-color: #75788d;
}

.social-icons.social-icons-colored .social-blogger a,
.social-icons.social-icons-colored-hover .social-blogger a:hover {
  background-color: #ff9233;
}

.social-icons.social-icons-colored .social-tumblr a,
.social-icons.social-icons-colored-hover .social-tumblr a:hover {
  background-color: #426d9b;
}

.social-icons.social-icons-colored .social-quora a,
.social-icons.social-icons-colored-hover .social-quora a:hover {
  background-color: #ea3d23;
}

.social-icons.social-icons-colored .social-github a,
.social-icons.social-icons-colored-hover .social-github a:hover {
  background-color: #3f91cb;
}

.social-icons.social-icons-colored .social-amazon a,
.social-icons.social-icons-colored-hover .social-amazon a:hover {
  background-color: #ff8e2e;
}

.social-icons.social-icons-colored .social-xing a,
.social-icons.social-icons-colored-hover .social-xing a:hover {
  background-color: #1a8e8c;
}

.social-icons.social-icons-colored .social-wikipedia a,
.social-icons.social-icons-colored-hover .social-wikipedia a:hover {
  background-color: #b3b5b8;
}

.social-icons.social-icons-border li a {
  border: 1px solid #d7d7d7;
  background: 0 0;
  color: #333;
}

.dark .social-icons.social-icons-border li a {
  border: 1px solid #333 !important;
}

.dark .social-icons li a .social-icons.social-icons-dark li a {
  background: #888;
  color: #fff;
}

.social-icons.social-icons-light li a {
  background: #fff;
  color: #333;
  border: 1px solid #eee;
}

.social-icons.social-icons-rounded li a {
  border-radius: 50%;
}

.social-icons.social-icons-square li a {
  border-radius: 0;
}

.social-icons.social-icons-xs li a {
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 12px;
}

.social-icons.social-icons-sm li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 13px;
}

.social-icons.social-icons-md li a {
  height: 38px;
  width: 38px;
  line-height: 38px;
  font-size: 16px;
}

.social-icons.social-icons-lg li a {
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-size: 18px;
}

.social-icons.social-icons-xl li a {
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-size: 18px;
}

.social-icons:not(.social-icons-colored):not(.social-icons-colored-hover) li a:hover {
  background-color: #eee;
}

.dark .social-icons:not(.social-icons-colored):not(.social-icons-colored-hover) li a:hover {
  background-color: #1f1f1f;
}

.social-icons li:hover i {
  -webkit-animation: toTopFromBottom .2s forwards;
  animation: toTopFromBottom .2s forwards;
}

/*Map*/
.leaflet-map {
  height: 350px;
  width: 100%;
}

/*table*/
table {
  width: 100%;
  margin-bottom: 1.5rem;
  border-collapse: collapse;
  vertical-align: middle;
}

table td, table th {
  padding: 10px 20px;
  border: 1px solid #eee;
  vertical-align: middle;
}

table thead > tr > th {
  vertical-align: middle;
  border-bottom: 0;
}

table p {
  margin-bottom: 0;
}

table.clean td, table.clean th {
  border: 0;
  border-top: 1px solid #eee;
}

table .product-thumbnail img {
  max-width: 80px;
}

/*divider*/
.divider {
  position: relative;
  overflow: hidden;
  height: 4px;
  z-index: 9;
}

.divider.center_icon {
  text-align: center;
  height: auto;
}

.divider::before, .divider::after {
  content: '';
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0px;
  height: 0;
  border-top: 1px solid #eee;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.divider::before {
  margin-top: -1px;
}

.divider::after {
  margin-top: 1px;
}

.divider i {
  background-color: #fff;
  color: #aaa;
  position: relative;
  z-index: 1;
  font-size: 20px;
  padding: 0 20px;
  line-height: 1;
}

/*COMPONENTS -> BANNERS*/
.banner-left-icon {
  position: relative;
  background: #fff;
}

.banner-left-icon:hover .banner-icon {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.banner-left-icon .banner-icon {
  max-width: 60px;
  margin-right: 20px;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.banner-left-icon .banner-text h3 {
  color: #242424;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.banner-left-icon .banner-text p {
  margin-bottom: 0;
  color: #90908e;
}

.banner-left-icon.style-2 {
  position: relative;
  border: 1px solid #eee;
  padding: 20px;
}

.banner-img {
  position: relative;
  clear: both;
}

.banner-img:hover img {
  opacity: 0.9;
}

.banner-img.banner-1 .banner-text {
  top: 30%;
}

.banner-img.banner-2 .banner-text {
  right: 10px;
}

.banner-img .banner-text {
  position: absolute;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 20px 30px;
}

.banner-img .banner-text span {
  color: #90908e;
}

.banner-img .banner-text h4 {
  font-weight: 600;
  margin-bottom: 15px;
}

.banner-img .banner-text:hover h4 {
  margin-left: 5px;
}

.banner-img .banner-text a i {
  margin-left: 5px;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  font-size: 10px;
}

.banner-img .banner-text a:hover i {
  margin-left: 10px;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.banner-img.style-2 {
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  height: 428px;
}

.banner-img.style-2 h4 {
  color: #fff;
}

.banner-big .btn {
  background: #3256e0;
  background: -webkit-gradient(linear, left top, right top, from(#3256e0), to(#3286e0));
  background: linear-gradient(90deg, #3256e0 0%, #3286e0 100%);
  color: #fff;
  border-radius: 50px;
  font-size: 13px;
  padding: 10px 24px;
  border: 0;
}

/*Deal banners*/
.deal {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  padding: 50px;
  background-color: #f5f6f9;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 420px;
}

.deal > div {
  width: 100%;
}

.deal h2 {
  color: #ff3551;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.1;
  margin-bottom: 5px;
  max-width: 240px;
}

.deal h5 {
  color: #242424;
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 20px;
  max-width: 240px;
}

.deal .deal-content {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.deal .product-title {
  max-width: 240px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.23;
}

.deal .product-title a {
  color: #111111;
}

.deal .product-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.25;
  margin-bottom: 20px;
  color: #ff3551;
}

.deal .product-price .new-price {
  font-weight: 400;
  letter-spacing: -.01em;
  margin-right: 10px;
}

.deal .product-price .old-price {
  color: #cccccc;
  text-decoration: line-through;
}

.deal .btn {
  background: #3256e0;
  background: -webkit-gradient(linear, left top, right top, from(#3256e0), to(#3286e0));
  background: linear-gradient(90deg, #3256e0 0%, #3286e0 100%);
  color: #fff;
  border-radius: 50px;
  font-size: 13px;
  padding: 10px 24px;
  border: 0;
}

.deal .btn i {
  margin-left: 5px;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  font-size: 10px;
}

.deal .btn:hover i {
  margin-left: 10px;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.deal .deal-bottom {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.deal .deal-bottom .deals-countdown {
  margin-left: -12px;
  margin-bottom: 20px;
}

.banner-bg {
  background-position: center;
  background-size: cover;
  padding: 50px;
}

/*Page > About*/
.hero-2 {
  padding: 160px 0 100px;
  min-height: 640px;
}

.parallax-wrapper {
  position: absolute;
  z-index: 3;
  width: 100%;
}

.parallax-wrapper .parallax-img-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 2;
  margin: 0 auto;
}

.parallax-wrapper .parallax-img-area .parallax-img {
  position: absolute;
  z-index: 2;
  text-align: right;
}

.parallax-wrapper .parallax-img-area .parallax-img.img-1 {
  right: 0;
  width: 52%;
  z-index: 2;
  top: 100px;
}

.parallax-wrapper .parallax-img-area .parallax-img.img-2 {
  right: 220px;
  width: 52%;
  top: 40px;
  z-index: 3;
}

.parallax-wrapper .parallax-img-area .parallax-img.img-3 {
  opacity: 0.1 !important;
  left: -210px;
  width: 320px;
  top: 193px;
}

.parallax-wrapper .parallax-img-area .parallax-img.img-4 {
  opacity: 0.08 !important;
  width: 180px;
  left: 50%;
}

.parallax-wrapper .parallax-img-area .parallax-img.img-5 {
  right: 0;
  width: 12%;
  z-index: 2;
  opacity: 0.3;
  bottom: 20%;
}

.parallax-wrapper .parallax-img-area .parallax-img.img-6 {
  width: 25%;
  z-index: 3;
  opacity: 0.2;
  bottom: 0;
  left: -150px;
}

.parallax-wrapper .parallax-img-area .parallax-img.img-7 {
  opacity: 0.2 !important;
  width: 16%;
  top: 10%;
  left: 10%;
}

.parallax-wrapper .parallax-img-area .parallax-img.img-8 {
  opacity: 0.2 !important;
  width: 10%;
  bottom: 40%;
  left: 50%;
}

.hero-content {
  position: absolute;
  z-index: 4;
  width: 100%;
}

.hero-content h1 {
  line-height: 1.1;
}

.about-count {
  z-index: 100;
  position: relative;
  margin-bottom: -100px;
}

.about-count .achievements-grid {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  grid-column-gap: 80px;
  -ms-grid-columns: auto auto auto auto;
  grid-template-columns: auto auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.about-count .w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.about-count .achievement-wrapper {
  text-align: center;
}

.about-count .achievement-number {
  margin-bottom: 8px;
  color: #1f2559;
  font-size: 3rem;
  line-height: 1.042em;
  font-weight: 800;
}

.about-count .achievement-text {
  max-width: 110px;
  margin: 0 auto;
}

.hero-card-icon {
  width: 65px;
  height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.hero-card-icon.icon-left {
  width: 165px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.single-content > ol {
  list-style-type: decimal;
  margin-bottom: 30px;
  padding-left: 1em;
}

.single-content > ol li:not(:last-child) {
  margin-bottom: 16px;
}

.single-content > ol ol {
  list-style-type: lower-alpha;
  margin: 20px 0 30px;
  padding-left: 25px;
}

.single-content > ol ol ol {
  list-style-type: lower-roman;
}

/*page 404*/
.page-404 {
  background-color: #fff;
}

.page-404 img {
  max-width: 300px;
}

.page-404 img.logo {
  max-width: 150px;
}

/*SHOP*/
.product-cart-wrap {
  position: relative;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  transition: .2s;
  -moz-transition: .2s;
  -webkit-transition: .2s;
  -o-transition: .2s;
}

.product-cart-wrap:hover {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}

.product-cart-wrap .product-img-action-wrap {
  position: relative;
}

.product-cart-wrap .product-img-action-wrap .product-img {
  position: relative;
  overflow: hidden;
}

.product-cart-wrap .product-img-action-wrap .product-img a img {
  width: 100%;
}

.product-cart-wrap .product-img-action-wrap .product-img a img.hover-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s opacity, 0.25s visibility, transform 1.5s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.product-cart-wrap .product-img-action-wrap .product-img-zoom a img {
  -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.product-cart-wrap .product-action-1 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  z-index: 9;
}

.product-cart-wrap .product-action-1.show {
  visibility: visible;
  opacity: 1;
  bottom: 26px;
  left: unset;
  right: 20px;
  top: unset;
  -webkit-transform: none;
          transform: none;
}

.product-cart-wrap .product-action-1 button, .product-cart-wrap .product-action-1 a.action-btn {
  border: none;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 5px;
  background-color: #f5f5f5;
  text-align: center;
  margin-right: 3px;
  position: relative;
  display: inline-block;
}

.product-cart-wrap .product-action-1 button.small, .product-cart-wrap .product-action-1 a.action-btn.small {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.product-cart-wrap .product-action-1 button.small i, .product-cart-wrap .product-action-1 a.action-btn.small i {
  font-size: 12px;
}

.product-cart-wrap .product-action-1 button:after, .product-cart-wrap .product-action-1 a.action-btn:after {
  bottom: 100%;
  left: 50%;
  position: absolute;
  white-space: nowrap;
  border-radius: 5px;
  font-size: 11px;
  padding: 7px 10px;
  color: #ffffff;
  background-color: #46a3bf;
  content: attr(aria-label);
  line-height: 1.3;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(0px);
  transform: translateX(-50%) translateY(0px);
}

.product-cart-wrap .product-action-1 button:before, .product-cart-wrap .product-action-1 a.action-btn:before {
  content: '';
  position: absolute;
  left: calc(50% - 7px);
  bottom: 100%;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  border: 7px solid transparent;
  border-top-color: #46a3bf;
  z-index: 9;
  margin-bottom: -13px;
  transition-delay: .1s;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
}

.product-cart-wrap .product-action-1 button:last-child, .product-cart-wrap .product-action-1 a.action-btn:last-child {
  margin-right: 0;
}

.product-cart-wrap .product-action-1 button i, .product-cart-wrap .product-action-1 a.action-btn i {
  font-size: 15px;
  color: #111111;
}

.product-cart-wrap .product-action-1 button:hover, .product-cart-wrap .product-action-1 a.action-btn:hover {
  background-color: #46a3bf;
}

.product-cart-wrap .product-action-1 button:hover:after, .product-cart-wrap .product-action-1 a.action-btn:hover:after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}

.product-cart-wrap .product-action-1 button:hover:before, .product-cart-wrap .product-action-1 a.action-btn:hover:before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.product-cart-wrap .product-action-1 button:hover i, .product-cart-wrap .product-action-1 a.action-btn:hover i {
  color: #fff;
}

.product-cart-wrap .product-badges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-cart-wrap .product-badges.product-badges-mrg {
  margin: 0 0 10px;
}

.product-cart-wrap .product-badges.product-badges-position {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 9;
}

.product-cart-wrap .product-badges span {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  border-radius: 30px;
  color: #ffffff;
  margin-right: 10px;
  padding: 6px 12px;
}

.product-cart-wrap .product-badges span:last-child {
  margin-right: 0px;
}

.product-cart-wrap .product-badges span.hot {
  background-color: #ff3551;
}

.product-cart-wrap .product-badges span.new {
  background-color: #46a3bf;
}

.product-cart-wrap .product-badges span.sale {
  background-color: #ffb300;
}

.product-cart-wrap .product-badges span.best {
  background-color: #3ed092;
}

.product-cart-wrap .product-content-wrap {
  padding: 20px;
}

.product-cart-wrap .product-content-wrap .product-category a {
  color: #90908e;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.product-cart-wrap .product-content-wrap .product-category a:hover {
  color: #46a3bf;
}

.product-cart-wrap .product-content-wrap h2 {
  font-size: 16px;
  border-bottom: 1px solid #eee;
  padding: 5px 0 10px 0;
  margin-bottom: 15px;
  font-weight: 400;
}

.product-cart-wrap .product-content-wrap h2 a {
  color: #111111;
}

.product-cart-wrap .product-content-wrap h2 a:hover {
  color: #46a3bf;
}

.product-cart-wrap .product-content-wrap .product-price {
  padding-top: 5px;
}

.product-cart-wrap .product-content-wrap .product-price span {
  font-size: 18px;
  font-weight: bold;
  color: #46a3bf;
}

.product-cart-wrap .product-content-wrap .product-price span.new-price {
  color: #46a3bf;
}

.product-cart-wrap .product-content-wrap .product-price span.old-price {
  font-size: 14px;
  font-weight: 400;
  color: #90908e;
  margin: 0 0 0 7px;
  text-decoration: line-through;
}

.product-cart-wrap .product-content-wrap .rating-result > span {
  display: block;
  overflow: hidden;
}

.product-cart-wrap .product-content-wrap .rating-result::before {
  font-family: "careem";
  font-size: 12px;
  letter-spacing: 3px;
  content: '\f005' '\f005' '\f005' '\f005' '\f005';
  color: #ffb300;
}

.product-cart-wrap .product-content-wrap .add-to-cart {
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 5px;
  background-color: #f5f5f5;
  text-align: center;
  display: block;
  color: #111111;
  font-size: 16px;
  position: absolute;
  bottom: 25px;
  right: 20px;
  font-weight: 300;
}

.product-cart-wrap .product-content-wrap .add-to-cart:hover {
  background-color: #46a3bf;
  color: #fff;
}

.product-cart-wrap .product-content-wrap .add-to-cart img {
  width: 20px;
  display: inline-block;
  margin-top: 11px;
}

.product-cart-wrap .product-stock .status-bar {
  background-color: #ededed;
  margin: 0px 0 10px;
  border-radius: 5px;
}

.product-cart-wrap .product-stock .status-bar .sold-bar {
  background-image: linear-gradient(235deg, #46a3bf 0%, #77ccfd 100%);
  border-radius: 4px;
  height: 8px;
}

.product-cart-wrap .product-stock .status-bar .sold-bar.sold-bar-width-33 {
  width: 33.333333333333%;
}

.product-cart-wrap .product-stock .status-bar .sold-bar.sold-bar-width-10 {
  width: 10%;
}

.product-cart-wrap .product-stock .status-bar .sold-bar.sold-bar-width-40 {
  width: 40%;
}

.product-cart-wrap .product-stock .status-bar .sold-bar.sold-bar-width-6 {
  width: 6.6666666666667%;
}

.product-cart-wrap .product-stock .status-bar .sold-bar.sold-bar-width-42 {
  width: 42.857142857143%;
}

.product-cart-wrap .product-stock .product-stock-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-cart-wrap .product-stock .product-stock-status .sold {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-right: 20px;
}

.product-cart-wrap .product-stock .product-stock-status .available {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.product-cart-wrap .product-stock .product-stock-status .stock-status-same-style span {
  font-size: 15px;
}

.product-cart-wrap .product-stock .product-stock-status .stock-status-same-style span.label {
  color: #444;
}

.product-cart-wrap .product-stock .product-stock-status .stock-status-same-style span.value {
  font-weight: 700;
  color: #333;
}

.product-cart-wrap:hover .product-img-action-wrap .product-img a img.hover-img {
  opacity: 1;
  visibility: visible;
}

.product-cart-wrap:hover .product-img-action-wrap .product-action-1 {
  opacity: 1;
  visibility: visible;
}

.product-cart-wrap:hover .product-img-zoom a img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1) translateZ(0);
  transform: scale3d(1.1, 1.1, 1.1) translateZ(0);
}

.product-cart-wrap.small {
  border: 0;
  text-align: center;
}

.product-cart-wrap.small:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.product-cart-wrap.small h2 {
  border-bottom: 0;
  padding: 0;
  margin-bottom: 0;
}

.product-cart-wrap.small .product-badges span {
  font-size: 10px;
}

.product-list-small article:not(:last-child) {
  margin-bottom: 20px;
}

.product-list-small .title-small {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 15px;
}

.product-list-small .title-small a {
  color: #111111;
}

.product-list-small .product-price {
  color: #46a3bf;
  font-size: 16px;
  font-weight: 500;
}

.product-list-small .product-price span.old-price {
  font-size: 14px;
  font-weight: 400;
  color: #90908e;
  margin: 0 0 0 7px;
  text-decoration: line-through;
}

.range .list-group-item {
  position: relative;
  display: block;
  padding: 0;
  background: none;
  border: 0;
}

.range .checkbox {
  font-size: 0.8em;
}

.range .price-filter {
  display: block;
  margin-top: 20px;
}

.range #slider-range {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  height: 4px;
  border-radius: 0px;
  background: #46a3bf;
  color: #46a3bf;
}

.range #slider-range .ui-slider-range {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #222;
  border-radius: 0px;
  border: none;
}

.range .ui-slider-handle.ui-state-default.ui-corner-all {
  width: 14px;
  height: 14px;
  line-height: 10px;
  background: #46a3bf;
  border: none;
  border-radius: 100%;
  top: -5px;
}

.range .label-input {
  margin-top: 15px;
}

.range .label-input span {
  margin-right: 5px;
  color: #282828;
}

.range .label-input input {
  border: none;
  margin: 0;
  height: unset;
  font-weight: 600;
  font-size: 14px;
  background: transparent;
  padding-left: 0;
}

.range .check-box-list {
  margin-top: 15px;
}

.range .check-box-list li {
  margin-bottom: 5px;
}

.range .check-box-list li:last-child {
  margin: 0;
}

.range .check-box-list li label {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  cursor: pointer;
}

.range .check-box-list li label input {
  display: inline-block;
  margin-right: 6px;
  position: relative;
  top: 1px;
}

.range .check-box-list .count {
  margin-left: 5px;
  color: #666;
}

.btn.btn-small {
  line-height: 1;
  padding: 10px 15px;
  min-width: unset;
  display: table;
  border-radius: 3px;
}

.product-sidebar .single-post {
  position: relative;
}

.product-sidebar .single-post:not(:last-child) {
  margin-bottom: 10px;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
}

.product-sidebar .single-post .content {
  padding-left: 95px;
}

.product-sidebar .single-post i {
  font-size: 12px;
}

.product-sidebar .image {
  height: 80px;
  width: 80px;
  float: left;
  margin-right: 10px;
  overflow: hidden;
}

.shop-product-fillter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 30px;
}

.shop-product-fillter.style-2 {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.shop-product-fillter .sort-by-product-area {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.shop-product-fillter .sort-by-product-area .sort-by-cover {
  position: relative;
}

.shop-product-fillter .sort-by-product-area .sort-by-product-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f7f8f9;
  border: 1px solid #f7f8f9;
  color: #777;
  padding: 11px 16px 11px 16px;
  border-radius: 26px;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  cursor: pointer;
}

.shop-product-fillter .sort-by-product-area .sort-by-product-wrap .sort-by {
  margin-right: 5px;
}

.shop-product-fillter .sort-by-product-area .sort-by-product-wrap .sort-by i {
  margin-right: 10px;
  font-size: 14px;
  color: #ababab;
  position: relative;
  top: 2px;
}

.shop-product-fillter .sort-by-product-area .sort-by-product-wrap .sort-by span {
  font-size: 13px;
  font-weight: 500;
}

.shop-product-fillter .sort-by-product-area .sort-by-product-wrap .sort-by-dropdown-wrap span {
  font-size: 13px;
  font-weight: 500;
  color: #4f5d77;
}

.shop-product-fillter .sort-by-product-area .sort-by-product-wrap .sort-by-dropdown-wrap span i {
  font-size: 15px;
  color: #4f5d77;
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.sort-by-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  right: 0;
  padding: 16px 0 21px;
  background: #fff;
  border: 0;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  min-width: 100%;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  color: #4f5d77;
  font-weight: 500;
}

.sort-by-dropdown.show {
  opacity: 1;
  visibility: visible;
}

.sort-by-dropdown ul li {
  display: block;
}

.sort-by-dropdown ul li a {
  font-weight: 500;
  font-size: 13px;
  padding: 5px 30px;
  display: block;
  position: relative;
  color: #4f5d77;
}

.sort-by-dropdown ul li a.active::before {
  content: '\f00c';
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 12px;
  color: #46a3bf;
  font-family: "careem";
  font-weight: 900;
}

.sort-by-dropdown ul li a:hover {
  background-color: #46a3bf;
  color: #ffffff;
}

/*Product list*/
.product-list {
  position: relative;
}

.product-list .product-cart-wrap {
  border: 0;
  border-radius: 0;
  overflow: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.product-list .product-cart-wrap:not(:last-child) {
  margin-bottom: 30px;
}

.product-list .product-cart-wrap:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.product-list .product-cart-wrap:hover .product-img {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}

.product-list .product-cart-wrap .product-img-action-wrap {
  max-width: 33%;
  position: relative;
}

.product-list .product-cart-wrap .product-img-action-wrap .product-img {
  border: 1px solid #eee;
  border-radius: 10px;
}

.product-list .product-cart-wrap .product-img-action-wrap .product-img .product-img-inner {
  overflow: hidden;
  padding: 10px;
}

.product-list .product-cart-wrap .product-img-action-wrap .product-img .product-img-inner a img {
  height: auto;
}

.product-list .product-cart-wrap h2 {
  font-size: 22px;
}

.product-list .product-cart-wrap .product-content-wrap .product-action-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  bottom: unset;
  padding: 0 20px;
  margin-top: 20px;
}

.product-list .product-cart-wrap .product-content-wrap .product-action-1 a.action-btn {
  border: none;
  width: auto;
  border-radius: 50px;
  background-color: #46a3bf;
  color: #fff;
  text-align: center;
  margin-right: 0;
  position: relative;
  display: inline-block;
  padding: 0px 23px;
  height: 44px;
  font-weight: 500;
}

.product-list .product-cart-wrap .product-content-wrap .product-action-1 a.action-btn i {
  margin-right: 8px;
  color: #fff;
}

/*PRODUCT DETAILS*/
.detail-gallery {
  position: relative;
}

.detail-gallery .zoom-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
}

.slider-nav-thumbnails .slick-slide {
  opacity: 0.5;
  position: relative;
}

.slider-nav-thumbnails .slick-slide.slick-current {
  opacity: 1;
}

.slider-nav-thumbnails .slick-slide.slick-current::before {
  border-bottom: 5px solid #333;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: -6px;
  width: 0;
}

.slider-nav-thumbnails .slick-slide.slick-current img {
  border: 1px solid #dbdbdb;
}

.slider-nav-thumbnails div.slick-slide {
  margin: 0 3px;
}

.slider-nav-thumbnails button.slick-arrow {
  margin: 0;
}

.slider-nav-thumbnails .slick-prev {
  left: 0;
}

.slider-nav-thumbnails .slick-next {
  right: 0;
}

.slider-nav-thumbnails .slick-prev,
.slider-nav-thumbnails .slick-next {
  font-size: 12px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.product-rate {
  background-image: url("../imgs/theme/rating-stars.png");
  background-position: 0 -100px;
  background-repeat: repeat-x;
  height: 14px;
  width: 70px;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}

.product-rating {
  height: 14px;
  background-repeat: repeat-x;
  background-image: url("../imgs/theme/rating-stars.png");
  background-position: 0 0;
}

.detail-info .product-price ins {
  font-size: 30px;
  text-decoration: none;
  font-weight: 600;
}

.detail-info .product-price ins span.old-price {
  text-decoration: line-through;
  color: #888;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.detail-info .product-meta {
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.list-filter {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-filter li {
  display: inline-block;
}

.list-filter li a {
  color: #555;
  display: block;
  min-width: 30px;
  text-align: center;
  position: relative;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}

.color-filter.list-filter a span {
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.color-filter.list-filter a span.product-color-white {
  border: 1px solid #ddd;
}

.color-filter.list-filter li.active a::before {
  content: "";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  right: 3px;
  top: -3px;
  background: #4cd964;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.size-filter.list-filter a {
  border-radius: 4px;
  padding: 0 7px;
  background: #fff;
}

.size-filter.list-filter li a {
  border: 1px solid #e5e5e5;
  color: #555;
  height: 30px;
  line-height: 30px;
  min-width: 30px;
  text-align: center;
  text-transform: uppercase;
}

.size-filter.list-filter li a:hover,
.size-filter.list-filter li.active a {
  color: #fff;
  background-color: #46a3bf;
  border-color: #46a3bf;
}

.detail-qty {
  max-width: 80px;
  padding: 9px 20px;
  position: relative;
  width: 100%;
  border-radius: 4px;
}

.detail-qty > a {
  font-size: 16px;
  position: absolute;
  right: 8px;
  color: #707070;
}

.detail-qty > a:hover {
  color: #46a3bf;
}

.detail-qty > a.qty-up {
  top: 0;
}

.detail-qty > a.qty-down {
  bottom: 0;
}

.attr-detail .select-box select {
  height: 40px;
  width: 100%;
}

.attr-detail.attr-brand {
  margin-top: 23px;
}

.attr-detail.attr-brand .select-box {
  display: block;
  margin-bottom: 20px;
}

.attr-detail.attr-color table {
  margin-bottom: 15px;
}

.detail-extralink > div {
  display: inline-block;
  vertical-align: top;
}

.detail-extralink .detail-qty {
  margin: 0 6px 15px 0;
  background: #fff;
}

.detail-info .product-price-cover {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.detail-info .product-price-cover .product-price {
  line-height: 1;
}

.button.button-add-to-cart {
  padding: 10px 40px;
}

.product-extra-link2 a {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e5e5e5;
  color: #333;
  border-radius: 4px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 40px;
  margin: 0 3px;
  transition: all 0.5s ease-out 0s;
  -webkit-transition: all 0.5s ease-out 0s;
}

.product-extra-link2 a:hover {
  background-color: #46a3bf;
  color: #fff;
}

.tab-style3 .nav-tabs .nav-item a.active {
  border-bottom-color: #46a3bf;
  color: #46a3bf;
}

.tab-style3 .nav-tabs li.nav-item a {
  background-color: transparent;
  display: block;
  padding: .5rem 1rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  text-align: center;
  text-transform: uppercase;
}

.tab-content.shop_info_tab {
  margin-top: 40px;
}

.tab-pane .comments-area {
  padding-top: 0;
  border: 0;
}

.product-color-red {
  background: #ff596d;
}

.product-color-yellow {
  background: #ffdb33;
}

.product-color-white {
  background: #ffffff;
}

.product-color-orange {
  background: #ffbb51;
}

.product-color-cyan {
  background: #80e6ff;
}

.product-color-green {
  background: #38cf46;
}

.product-color-purple {
  background: #ff8ff8;
}

.detail-gallery .slick-slider {
  background-color: #000;
  margin-bottom: 30px;
  border-radius: 10px;
}

.detail-gallery .slick-slider img {
  opacity: 0.96;
}

.detail-gallery .slick-slider.slider-nav-thumbnails {
  background: none;
  border-radius: 0;
}

.detail-gallery .slick-slider button.slick-arrow {
  background: none;
  border: 0;
  padding: 0;
  font-size: 14px;
}

.detail-gallery .slick-slider button.slick-arrow i {
  color: #90908e;
}

.mail-to-friend {
  color: #90908e;
  font-size: 12px;
}

.mail-to-friend i {
  margin-right: 5px;
}

.attr-color, .attr-size {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.product-detail-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: flex-end;
}

.product-more-infor {
  padding: 0 0 0px 14px;
}

.product-more-infor li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 10px;
  position: relative;
}

.product-more-infor li ::before {
  position: absolute;
  left: -14px;
  top: 9px;
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #9b9b9b;
}

.product-more-infor li span {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 165px;
  flex: 0 0 165px;
  display: inline-block;
}

.product-more-infor li:last-child {
  margin: 0 0 0;
}

.progress + .progress {
  margin-top: 1rem;
}

.progress span {
  line-height: 16px;
  background: #fff;
  padding-right: 10px;
  width: 45px;
}

.progress-bar {
  background-color: #46a3bf;
}

.shop-filter-toogle {
  margin-bottom: 20px;
  display: block;
  position: relative;
}

.shop-filter-toogle i {
  margin-left: 5px;
}

.shop-filter-toogle i.angle-down {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.shop-filter-toogle.active i.angle-down {
  opacity: 1;
  visibility: visible;
  display: inline-block;
}

.shop-filter-toogle.active i.angle-up {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.shop-product-fillter-header {
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 50px;
  padding: 30px;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
}

.shop-product-fillter-header .categor-list li {
  font-size: 12px;
}

.shop-product-fillter-header .categor-list li + li {
  border-top: 1px solid #f7f8f9;
  padding-top: 5px;
  margin-top: 5px;
}

.shop-product-fillter-header .categor-list li a {
  font-size: 14px;
  color: #4f5d77;
  margin-right: 10px;
}

.shop-product-fillter-header .categor-list li a:hover {
  color: #46a3bf;
}

.shop-product-fillter-header .color-filter {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.shop-product-fillter-header .product-rate-cover + .product-rate-cover {
  margin-top: 15px;
}

.shopping-summery tbody tr img {
  border-radius: 0;
  max-width: 80px;
}

.cart-totals {
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
}

.order_review {
  border: 1px solid #eee;
  padding: 30px;
  border-radius: 10px;
}

.toggle_info {
  padding: 20px;
  background-color: #f7f8f9;
  border-radius: 10px;
  border: 1px solid #eee;
}

.login_form .panel-body, .coupon_form .panel-body {
  border: 1px solid #eee;
  padding: 30px;
  margin-top: 30px;
  border-radius: 10px;
}

.payment_option .custome-radio {
  margin-bottom: 10px;
}

.payment_option .custome-radio .form-check-label {
  color: #292b2c;
  font-weight: 600;
}

.custome-radio .form-check-label::before {
  content: "";
  border: 1px solid #908f8f;
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 8px;
}

.custome-radio input[type="radio"] + .form-check-label::after {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  left: 3px;
  opacity: 0;
}

.custome-radio input[type="radio"]:checked + .form-check-label::after {
  opacity: 1;
  background-color: #46a3bf;
}

/*BLOG*/
.post-list article:not(:last-child) {
  margin-bottom: 20px;
}

.post-list .post-thumb {
  max-width: 200px;
  overflow: hidden;
}

.post-list .post-thumb a {
  margin-bottom: 0;
}

.post-list .post-title {
  font-weight: 500;
}

.post-list .post-title a {
  color: #111111;
}

.single-content {
  text-rendering: optimizeLegibility;
}

.single-content h1 {
  font-size: 56px;
  line-height: 72px;
  margin-bottom: 32px;
}

.single-content h2 {
  font-size: 48px;
  line-height: 64px;
  margin-bottom: 30px;
}

.single-content h3 {
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 28px;
}

.single-content h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 26px;
}

.single-content h5 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

.single-content h6 {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: 22px;
}

.single-content p {
  margin-bottom: 1.2em;
  font-weight: 400;
}

.single-content ul {
  list-style: circle;
  padding-left: 1rem;
}

.single-content ul li {
  position: relative;
  margin-bottom: 7px;
}

.single-header {
  margin-bottom: 30px;
}

.single-header.style-2 {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.single-header .entry-meta.meta-1 {
  display: block;
}

.single-header .single-header-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.single-thumbnail {
  margin-bottom: 30px;
}

.single-thumbnail img {
  border-radius: 10px;
  overflow: hidden;
}

.entry-bottom {
  border-top: 1px solid #eee;
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*Entry meta*/
.entry-meta {
  line-height: 1;
}

.entry-meta.meta-2 .author-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.entry-meta.meta-2 .author-name {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  margin-top: 5px;
}

.entry-meta .author-add {
  font-size: 12px;
}

.entry-meta.meta-1 span {
  margin-right: 10px;
}

.entry-meta.meta-1 span i {
  margin-right: 3px;
}

.entry-meta.meta-0 span {
  padding: 4px 10px 4px 19px;
  font-size: 11px;
  letter-spacing: 0.8px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 30px;
  position: relative;
}

.entry-meta.meta-0 span::before {
  content: "";
  width: 6px;
  height: 6px;
  background: none;
  margin-right: 3px;
  border-radius: 5px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 8px;
  margin-top: -3px;
  border: 1px solid #46a3bf;
}

span.has-dot {
  position: relative;
  padding-left: 10px;
}

span.has-dot::before {
  content: "";
  width: 4px;
  height: 4px;
  background: #999;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  display: block;
  left: -2px;
}

h6.post-title {
  font-size: 14px;
}

.post-title a {
  color: #111111;
}

.post-title a:hover {
  color: #46a3bf;
}

.post-thumb {
  overflow: hidden;
  position: relative;
}

.post-thumb.border-radius-5 img {
  border-radius: 5px;
}

.post-thumb a {
  line-height: 1;
}

.post-thumb .entry-meta {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}

.post-thumb .entry-meta a {
  display: inline-block;
  color: #fff !important;
  background-color: #46a3bf;
  border-radius: 30px;
  padding: 8px 16px;
}

/*Loop Grid*/
.loop-grid {
  position: relative;
}

.loop-grid article {
  position: relative;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #eee;
}

.loop-grid .entry-content {
  padding: 30px;
}

.loop-grid .entry-content-2 {
  padding: 20px;
}

.loop-grid.loop-list {
  position: relative;
}

.loop-grid.loop-list article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.loop-grid.loop-list article .post-thumb {
  min-height: 280px;
  min-width: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.img-hover-slide {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 280px;
  overflow: hidden;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}

.top-right-icon {
  position: absolute;
  bottom: 15px;
  right: 15px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  z-index: 3;
  color: #fff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  -ms-transition: all 0.4s ease;
}

.entry-meta.meta-1, .entry-meta.meta-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.entry-meta a.read-more {
  font-size: 12px;
  border-radius: 30px;
  border: 1px solid #abd7ab;
  padding: 10px 15px;
  color: #98ca98;
  font-weight: 600;
  display: inline-block;
}

/*LAYOUT -> HEADER STYLE*/
/*Header default*/
.header-top-ptb-1 {
  padding: 7px 0;
  border-bottom: 1px solid #f2f2f2;
  background-color: #f2f3f8;
  font-size: 13px;
}

.header-info-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header-info > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-info > ul > li {
  margin-right: 30px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-info > ul > li:before {
  content: '';
  position: absolute;
  right: -14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 20px;
  background: #dedfe2;
}

.header-info > ul > li:last-child {
  margin-right: 0;
}

.header-info > ul > li:last-child:before {
  display: none;
}

.header-info > ul > li a {
  color: #444;
  font-weight: 500;
}

.header-info > ul > li a i {
  font-size: 14px;
  margin-right: 5px;
}

.header-info > ul > li a.language-dropdown-active i.fa-chevron-down {
  font-size: 8px;
  margin-left: 5px;
}

.header-info > ul > li > ul.language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  min-width: 200px;
  background: #fff;
  border-radius: 5px;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  visibility: hidden;
  opacity: 0;
  padding: 20px 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-info > ul > li > ul li {
  display: block;
}

.header-info > ul > li > ul li a {
  display: block;
  color: #696969;
  padding: 5px 20px;
}

.header-info > ul > li > ul li a:hover {
  color: #46a3bf;
  background-color: rgba(78, 151, 253, 0.1);
}

.header-info > ul > li:hover > a {
  color: #333;
}

.header-info > ul > li:hover > ul.language-dropdown {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.header-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-wrap.header-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.logo a {
  display: block;
}

.logo.logo-width-1 a img {
  width: 150px;
}

.search-style-1 form {
  width: 370px;
  position: relative;
}

.search-style-1 form input {
  font-size: 14px;
  height: 48px;
  color: #444;
  border-radius: 26px;
  padding: 3px 50px 3px 20px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-action .header-action-icon.header-action-mrg-none2 {
  margin-right: 0px;
}

.header-action .header-action-icon:last-child {
  margin-right: 0;
}

.header-action .header-action-icon > a span.pro-count {
  position: absolute;
  right: -11px;
  bottom: -8px;
  color: #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}

.header-action-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-action-2 .header-action-icon-2:last-child {
  padding: 0 0px 0 14px;
}

.header-action-2 .header-action-icon-2 > a {
  font-size: 30px;
  color: #333;
  line-height: 1;
  display: inline-block;
  position: relative;
  width: 1em;
}

.header-action-2 .header-action-icon-2 > a img {
  width: 100%;
}

.header-action-2 .header-action-icon-2 > a span.pro-count {
  position: absolute;
  right: -11px;
  top: -5px;
  color: #ffffff;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  line-height: 18px;
}

.cart-dropdown-wrap ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 20px;
}

.cart-dropdown-wrap ul li .shopping-cart-img a {
  display: block;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a:hover {
  background-color: #3f81eb;
}

.header-height-2 {
  min-height: 200px;
}

.header-height-3 {
  min-height: 132px;
}

.header-height-4 {
  min-height: 120px;
}

.search-style-2 form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.search-style-2 form input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.main-categori-wrap > a > i {
  font-size: 14px;
  margin-left: 15px;
  font-weight: 300;
}

.hotline p i {
  font-size: 12px;
  margin-right: 10px;
}

.search-popup-wrap.search-visible {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.mobile-header-wrapper-style.sidebar-visible {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a {
  display: block;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav {
  height: 100%;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .main-categori-wrap a i {
  margin-right: 15px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .main-categori-wrap .categori-dropdown-active-small {
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .main-categori-wrap .categori-dropdown-active-small a {
  color: rgba(255, 255, 255, 0.7);
}

.header-action-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-style-1 {
  margin-right: 28px;
}

.search-style-1 form input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-1 form input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-1 form input:focus {
  border: 1px solid #46a3bf;
  background-color: #ffffff;
}

.search-style-1 form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  border: none;
  font-size: 16px;
  height: 100%;
  padding: 0 15px;
  background-color: transparent;
  color: #46a3bf;
}

.search-style-1 form button:hover {
  color: #3f81eb;
}

.header-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-action .header-action-icon {
  margin-right: 28px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-action .header-action-icon.header-action-mrg-none {
  margin-right: 13px;
}

.header-action .header-action-icon > a {
  font-size: 24px;
  color: #333;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.header-action .header-action-icon > a span.pro-count.blue {
  background-color: #46a3bf;
}

.header-action .header-action-icon:hover > a {
  color: #46a3bf;
}

.header-action .header-action-icon:hover .cart-dropdown-wrap {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header-action.header-action-hm3 .header-action-icon.header-action-mrg-none2 {
  margin-right: 0px;
}

.header-action.header-action-hm3 .header-action-icon:last-child {
  margin-right: 0;
}

.header-action-2 .header-action-icon-2 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 14px;
}

.header-action-2 .header-action-icon-2 > a svg {
  width: 1em;
  height: 1em;
}

.header-action-2 .header-action-icon-2 > a span.pro-count.blue {
  background-color: #46a3bf;
}

.header-action-2 .header-action-icon-2:hover > a {
  color: #46a3bf;
  fill: #46a3bf;
}

.header-action-2 .header-action-icon-2:hover .cart-dropdown-wrap {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header-middle-ptb-1 {
  padding: 25px 0;
}

.cart-dropdown-wrap {
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  z-index: 99;
  width: 360px;
  background-color: #fff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
  padding: 30px 20px 27px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 5px;
}

.cart-dropdown-wrap.cart-dropdown-hm2 {
  right: -63px;
}

.cart-dropdown-wrap ul li:last-child {
  margin: 0 0 0px;
}

.cart-dropdown-wrap ul li .shopping-cart-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  margin-right: 20px;
}

.cart-dropdown-wrap ul li .shopping-cart-img a img {
  max-width: 100%;
}

.cart-dropdown-wrap ul li .shopping-cart-title {
  margin: 6px 0 0;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 9px;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 a {
  color: #696969;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 a:hover {
  color: #46a3bf;
}

.cart-dropdown-wrap ul li .shopping-cart-title h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 0px;
  color: #46a3bf;
}

.cart-dropdown-wrap ul li .shopping-cart-title h3 span {
  color: #696969;
  font-weight: 400;
  font-size: 14px;
}

.cart-dropdown-wrap ul li .shopping-cart-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 100;
  -ms-flex-positive: 100;
  flex-grow: 100;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 6px 0 0;
}

.cart-dropdown-wrap ul li .shopping-cart-delete a {
  font-size: 18px;
  color: #696969;
}

.cart-dropdown-wrap ul li .shopping-cart-delete a:hover {
  color: #333;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total {
  border-top: 2px solid #f3f3f3;
  margin: 27px 0 20px;
  padding: 17px 0 0;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total h4 {
  color: #9b9b9b;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total h4 span {
  font-size: 18px;
  float: right;
  color: #46a3bf;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a {
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 50px;
  line-height: 1;
  padding: 16px 42px 17px;
  background-color: #46a3bf;
}

.sticky-bar.stick {
  -webkit-animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
}

.sticky-bar.stick.sticky-white-bg {
  background-color: #fff;
}

.sticky-bar.stick.sticky-blue-bg {
  background-color: #3286e0;
}

.header-height-1 {
  min-height: 133px;
}

.search-style-2 form select {
  color: #444;
  font-size: 14px;
  min-height: 52px;
  border: 2px solid #eee;
  border-right: none;
  width: 170px;
  padding: 3px 35px 3px 20px;
  border-radius: 26px 0 0 26px;
  background-color: #f8f8f8;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
}

.search-style-2 form select:focus {
  color: #333;
}

.search-style-2 form select::after {
  position: absolute;
  content: "asdf";
}

.search-style-2 form input {
  color: #444;
  font-size: 14px;
  min-height: 52px;
  border: 2px solid #eee;
  width: 540px;
  padding: 3px 62px 3px 20px;
  border-radius: 0px 26px 26px 0px;
  background-color: #fff;
}

.search-style-2 form input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-2 form input:focus {
  border: 2px solid #46a3bf;
}

.search-style-2 form button {
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  padding: 0;
  border: none;
  font-size: 20px;
  color: #46a3bf;
  height: 100%;
  padding: 5px 17px;
}

.header-bottom-bg-color {
  background-color: #46a3bf;
}

.header-bottom-shadow {
  -webkit-box-shadow: 0 1px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.05);
}

.header-bottom .header-action-right a span.pro-count {
  background-color: #fff;
  color: #46a3bf;
}

.search-style-3 form {
  position: relative;
}

.search-style-3 form input {
  font-size: 14px;
  height: 40px;
  color: #444;
  border-radius: 26px;
  padding: 3px 50px 3px 20px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-style-3 form input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-3 form input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-3 form input:focus {
  border: 1px solid #46a3bf;
}

.search-style-3 form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  border: none;
  font-size: 14px;
  height: 100%;
  padding: 0 14px;
  background-color: transparent;
  color: #333;
}

.search-style-3 form button:hover {
  color: #46a3bf;
}

.search-popup-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: white;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.search-popup-wrap .search-popup-content form input {
  width: 1170px;
  background-color: transparent;
  border: 0;
  border-bottom: 3px solid #46a3bf;
  font-size: 50px;
  padding: 21px 50px 16px 0px;
  color: #919191;
  font-weight: 300;
  max-width: 100%;
  height: auto;
  -webkit-transform: translate(0, 50px);
  transform: translate(0, 50px);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
  -webkit-transition: all .5s ease-in-out, opacity .5s linear;
  transition: all .5s ease-in-out, opacity .5s linear;
}

.search-popup-wrap .search-popup-content form input::-moz-input-placeholder {
  color: #919191;
  opacity: 1;
}

.search-popup-wrap .search-popup-content form input::-webkit-input-placeholder {
  color: #919191;
  opacity: 1;
}

.search-popup-wrap.search-visible .search-popup-content form input {
  -webkit-transform: none;
  transform: none;
  opacity: 1;
  -webkit-transition-delay: .6s;
  transition-delay: .6s;
}

.close-style-wrap {
  position: absolute;
  right: 55px;
  top: 40px;
}

.close-style-wrap.close-style-position-inherit {
  position: inherit;
}

.close-style-wrap .close-style {
  position: relative;
  background-color: transparent;
  padding: 0;
  border: none;
  width: 40px;
  height: 40px;
}

.close-style-wrap .close-style > i {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 29px;
  height: 2px;
  margin: 0 !important;
  -webkit-transform-origin: center;
  transform-origin: center;
  display: block;
  overflow: hidden;
}

.close-style-wrap .close-style > i.icon-top {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-style-wrap .close-style > i.icon-top::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  -webkit-transform-origin: right;
  transform-origin: right;
}

.close-style-wrap .close-style > i.icon-top::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scaleX(0) translateZ(0);
  transform: scaleX(0) translateZ(0);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.close-style-wrap .close-style > i.icon-bottom {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.close-style-wrap .close-style > i.icon-bottom::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  -webkit-transform: scaleX(1) translateZ(0);
  transform: scaleX(1) translateZ(0);
  -webkit-transform-origin: right;
  transform-origin: right;
}

.close-style-wrap .close-style > i.icon-bottom::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transform: scaleX(0) translateZ(0);
  transform: scaleX(0) translateZ(0);
  -webkit-transform-origin: left;
  transform-origin: left;
}

.close-style-wrap .close-style > i::before,
.close-style-wrap .close-style > i::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #111;
}

.burger-icon {
  position: relative;
  width: 24px;
  height: 20px;
  cursor: pointer;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-icon > span {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
}

.burger-icon > span.burger-icon-top {
  top: 2px;
}

.burger-icon > span.burger-icon-bottom {
  bottom: 2px;
}

.burger-icon > span.burger-icon-mid {
  top: 9px;
}

.burger-icon > span::before,
.burger-icon > span::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #333;
}

.burger-icon.burger-icon-white > span::before,
.burger-icon.burger-icon-white > span::after {
  background-color: #ffffff;
}

.body-overlay-1 {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  visibility: hidden;
  width: 100%;
  cursor: crosshair;
  z-index: 9999;
}

.mobile-menu-active .body-overlay-1 {
  opacity: 1;
  visibility: visible;
}

.main-wrapper {
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-header-wrapper-style {
  position: fixed;
  top: 0;
  width: 360px;
  min-height: 100vh;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform: translate(200px, 0);
  transform: translate(200px, 0);
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 99999;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner {
  padding: 0px 0px 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #46a3bf;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px 30px;
  background-color: #ffffff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
  width: 120px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
  padding: 30px 30px 30px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li {
  display: block;
  position: relative;
  padding: 18px 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:last-child {
  border-bottom: none;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children .menu-expand {
  top: 10px;
  right: 0%;
  position: absolute;
  cursor: pointer;
  z-index: 9;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 100%;
  background-color: transparent;
  color: #ffffff;
  line-height: 40px;
  font-size: 14px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children .menu-expand i {
  font-size: 14px;
  font-weight: 300;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children.active > .menu-expand {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  content: "\f106";
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a {
  font-size: 14px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  display: inline-block;
  color: #ffffff;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i {
  margin-right: 5px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
  padding: 18px 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 20px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
  padding: 10px 0 12px;
  border-bottom: none;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.menu-item-has-children .menu-expand {
  top: 0px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul {
  margin-top: 32px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: #ffffff;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap {
  padding: 25px 0 30px;
  margin-bottom: 30px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info {
  position: relative;
  margin-bottom: 13px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:last-child {
  margin-bottom: 0;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a {
  font-size: 14px;
  display: block;
  color: #ffffff;
  font-weight: 500;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a span {
  float: right;
  font-size: 18px;
  position: relative;
  top: 8px;
  line-height: 1;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 0 0 0;
  width: 100%;
  z-index: 11;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover {
  color: #46a3bf;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:hover > a {
  color: rgba(255, 255, 255, 0.7);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-social-icon a {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  margin-right: 10px;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.mobile-social-icon a:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.mobile-social-icon a:last-child {
  margin-right: 0;
}

.mobile-social-icon a.facebook {
  background-color: #3b5999;
  border: 1px solid #3b5999;
}

.mobile-social-icon a.facebook:hover {
  color: #333;
  background-color: #ffffff;
}

.mobile-social-icon a.twitter {
  background-color: #55acee;
  border: 1px solid #55acee;
}

.mobile-social-icon a.twitter:hover {
  color: #333;
  background-color: #ffffff;
}

.mobile-social-icon a.tumblr {
  background-color: #35465c;
  border: 1px solid #35465c;
}

.mobile-social-icon a.tumblr:hover {
  color: #333;
  background-color: #ffffff;
}

.mobile-social-icon a.instagram {
  background-color: #e4405f;
  border: 1px solid #e4405f;
}

.mobile-social-icon a.instagram:hover {
  color: #333;
  background-color: #ffffff;
}

.mobile-search {
  padding: 30px 0 30px;
}

.categori-button-active-2 {
  padding: 21px 0;
  display: block !important;
}

.categori-button-active-2 i {
  float: right;
  position: relative;
  top: 5px;
}

#news-flash {
  min-width: 400px;
  margin-bottom: -8px;
}

.header-info i {
  font-size: 12px;
  color: #a6a6a6;
}

/* Header style 2 */
.header-style-2 .header-top-ptb-1 {
  padding: 9px 0;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 300;
}

.header-style-2 .header-middle-ptb-1 {
  padding: 35px 0;
}

.header-style-2 .select2-container--default .select2-selection--single {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-radius: 0 !important;
  height: 52px;
  padding-left: 20px;
}

.header-style-2 .search-style-2 form input {
  color: #444;
  border: 1px solid #e8e8e8;
  width: 540px;
  padding: 3px 62px 3px 20px;
  border-radius: 0;
  background-color: #fff;
}

.header-style-2 .search-style-2 form button {
  color: #fff;
  background: #46a3bf;
}

/* Header style 3 */
.header-style-3 .header-top-ptb-1 {
  padding: 9px 0;
  border: 0;
  background-color: #46a3bf;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 300;
  color: #fff;
}

.header-style-3 .header-top-ptb-1 a {
  color: #fff;
}

.header-style-3 .header-top-ptb-1 i {
  color: #fff;
}

.header-style-3 .header-top-ptb-1 .language-dropdown a {
  color: #111111;
}

.header-style-3 .header-middle-ptb-1 {
  padding: 35px 0;
}

.header-style-3 .header-bottom-bg-color {
  background-color: #fff;
}

.header-style-3 .main-categori-wrap > a {
  color: #494949;
}

.header-style-3 .main-menu.main-menu-light-white > nav > ul > li > a {
  color: #494949;
}

.header-style-3 .main-nav {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.header-style-3 .sticky-bar.stick.sticky-blue-bg {
  background-color: #fff;
}

.header-style-3 .hotline p {
  color: #46a3bf !important;
}

/* Header style 4 */
.header-style-4 {
  position: relative;
}

.header-style-4 .header-top-ptb-1 {
  padding: 9px 0;
  border-bottom: 1px solid #111111;
  background-color: #111111;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 300;
  color: #fff;
}

.header-style-4 .header-top-ptb-1 a {
  color: #fff;
}

.header-style-4 .header-top-ptb-1 i {
  color: #fff;
}

.header-style-4 .header-top-ptb-1 .language-dropdown a {
  color: #111111;
}

.header-style-4 .header-middle-ptb-1 {
  padding: 40px 0;
}

.header-style-4 .select2-container--default .select2-selection--single {
  background-color: #46a3bf;
  border: 2px solid #eee;
  border-radius: 30px 0 0 30px !important;
  border-right: 0;
  height: 52px;
  padding-left: 20px;
}

.header-style-4 .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #fff;
}

.header-style-4 .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent transparent transparent;
}

.header-style-4 .header-bottom-bg-color {
  background-color: #fff;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.header-style-4 .main-categori-wrap > a {
  color: #fff;
  line-height: 60px;
  background: #46a3bf;
  padding: 0 30px;
}

.header-style-4 .main-categori-wrap > a i.up {
  right: 30px;
}

.header-style-4 .main-menu.main-menu-light-white > nav > ul > li > a {
  color: #494949;
}

.header-style-4 .stick .main-menu.main-menu-light-white > nav > ul > li > a {
  color: #ffffff !important;
}

.header-style-4 .categori-dropdown-active-large {
  top: 100%;
}

.header-style-4 .hotline p {
  color: #46a3bf !important;
}

.header-style-4 .stick .hotline p {
  color: #fff !important;
}

.header-style-5 .select2-container--default {
  width: 115px !important;
}

.header-style-5 .select2-container--default .select2-selection--single {
  border: 1px solid #e8e8e8;
  height: 45px;
  padding-left: 10px;
  font-size: 13px;
}

.header-style-5 .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px;
  font-size: 13px;
}

.header-style-5 .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 10px;
  right: 7px;
}

.header-style-5 .search-style-2 form input {
  color: #444;
  border: 1px solid #e8e8e8;
  width: 240px;
  padding: 3px 62px 3px 20px;
  background-color: #fff;
  min-height: 45px;
  line-height: 45px;
  font-size: 13px;
}

.header-style-5 .search-style-2 form button {
  font-size: 16px;
}

.header-style-5 .header-bottom {
  border-bottom: 1px solid #f7f8f9;
}

.header-style-5 .header-bottom .header-action-right a span.pro-count {
  color: #fff;
}

.header-style-5 .header-action-2 .header-action-icon-2 > a {
  font-size: 24px;
}

/*LAYOUT -> NAVIGATION STYLE*/
.main-menu.main-menu-mrg-1 {
  margin: 0 0 0 27px;
}

.main-menu > nav > ul > li.position-static {
  position: static;
}

.main-menu > nav > ul > li > a {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #444;
}

.main-menu > nav > ul > li > a i {
  font-size: 8px;
  position: relative;
  top: -3px;
  margin-left: 4px;
}

.main-menu > nav > ul > li > a.active {
  color: #46a3bf;
}

.main-menu > nav > ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  width: 275px;
  padding: 33px 0 35px;
  -webkit-transition: all .25s ease 0s;
  transition: all .25s ease 0s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  border-radius: 5px;
  z-index: 999;
}

.main-menu > nav > ul > li ul.sub-menu li {
  line-height: 1;
  display: block;
  margin-bottom: 21px;
  position: relative;
}

.main-menu > nav > ul > li ul.sub-menu li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li ul.sub-menu li ul.level-menu {
  position: absolute;
  left: 100%;
  top: -110px;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  width: 240px;
  padding: 33px 0 35px;
  -webkit-transition: all .25s ease 0s;
  transition: all .25s ease 0s;
  opacity: 0;
  visibility: hidden;
  border-radius: 5px;
  margin-top: 20px;
}

.main-menu > nav > ul > li ul.sub-menu li ul.level-menu.level-menu-modify {
  top: -25px;
}

.main-menu > nav > ul > li ul.sub-menu li a i {
  font-size: 9px;
  float: right;
  position: relative;
  top: 4px;
}

.main-menu > nav > ul > li ul.sub-menu li:hover > a {
  color: #000;
}

.main-menu > nav > ul > li ul.sub-menu li:hover ul.level-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.main-menu > nav > ul > li ul.mega-menu {
  position: absolute;
  left: 0;
  top: 100%;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  width: 100%;
  padding: 35px 25px 35px 35px;
  -webkit-transition: all .25s ease 0s;
  transition: all .25s ease 0s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  border-radius: 5px;
  z-index: 999;
}

.main-menu > nav > ul > li ul.mega-menu li {
  line-height: 1;
  display: block;
  position: relative;
  float: left;
  padding-right: 10px;
}

.main-menu > nav > ul > li ul.mega-menu li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-22 {
  width: 22%;
}

.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-34 {
  width: 34%;
}

.main-menu > nav > ul > li ul.mega-menu li a.menu-title {
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.main-menu > nav > ul > li ul.mega-menu li ul {
  margin-top: 28px;
}

.main-menu > nav > ul > li ul.mega-menu li ul li {
  line-height: 1;
  display: block;
  margin-bottom: 14px;
  float: none;
}

.main-menu > nav > ul > li ul.mega-menu li ul li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li ul.mega-menu li ul li a {
  font-size: 14px;
  color: #555;
  display: block;
  line-height: 1.4;
}

.main-menu > nav > ul > li ul.mega-menu li ul li a:hover {
  color: #000;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap {
  overflow: hidden;
  position: relative;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap a {
  display: block;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap a img {
  width: 100%;
  -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content {
  position: absolute;
  top: 32px;
  left: 30px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  margin: 5px 0 11px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price span.old-price {
  font-size: 16px;
  font-weight: 400;
  color: #696969;
  text-decoration: line-through;
  margin-left: 5px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-btn a {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 26px;
  padding: 12px 22px 14px;
  background-color: #46a3bf;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount h3 span {
  display: block;
  line-height: 1;
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 3px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap:hover a img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap:hover .menu-banner-btn a {
  background-color: #ff3551;
}

.main-menu > nav > ul > li:hover > a {
  color: #46a3bf;
}

.main-menu > nav > ul > li:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.main-menu > nav > ul > li:hover ul.mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.main-menu.hover-boder > nav > ul > li > a {
  position: relative;
}

.main-menu.hover-boder > nav > ul > li > a::after {
  content: none;
  position: absolute;
  left: auto;
  right: 0;
  bottom: 34px;
  height: 1px;
  width: 0;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: #46a3bf;
}

.main-menu.hover-boder > nav > ul > li:hover > a::after {
  width: 100%;
  left: 0;
  right: auto;
}

.main-menu.hover-boder.hover-boder-white > nav > ul > li > a::after {
  bottom: 18px;
  background: #ffffff;
}

.main-menu.hover-boder.hover-boder-modify > nav > ul > li > a::after {
  bottom: 28px;
}

.main-menu.main-menu-light-white > nav > ul > li > a {
  color: white;
}

.main-menu.main-menu-padding-1 > nav > ul > li {
  padding: 0 17px;
}

.main-menu.main-menu-lh-2 > nav > ul > li {
  line-height: 60px;
}

.main-menu.main-menu-lh-3 > nav > ul > li {
  line-height: 80px;
}

.main-menu.main-menu-grow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.main-menu > nav > ul > li {
  display: inline-block;
  position: relative;
}

.main-menu > nav > ul > li ul.sub-menu li a {
  font-size: 14px;
  color: #555;
  display: block;
  padding: 0 30px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content h4 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price {
  margin: 0 0 28px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price span.new-price {
  font-size: 24px;
  font-weight: 700;
  color: #ff3551;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffd55a;
  position: absolute;
  top: 13%;
  right: 7%;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount h3 {
  color: #333;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.main-menu.main-menu-lh-1 > nav > ul > li {
  line-height: 92px;
}

.main-categori-wrap {
  position: relative;
}

.main-categori-wrap > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
}

.main-categori-wrap > a span {
  margin-right: 10px;
}

.main-categori-wrap > a > i.up {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.main-categori-wrap > a.open > i {
  opacity: 0;
  visibility: hidden;
}

.main-categori-wrap > a.open > i.up {
  opacity: 1;
  visibility: visible;
}

.hotline p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hotline p span {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 400;
}

.categori-dropdown-active-large {
  list-style-type: none;
  position: absolute;
  top: 177%;
  left: 0;
  z-index: 9;
  margin: 0;
  padding: 15px 0 10px 0;
  background: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  font-weight: 400;
  min-width: 270px;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  visibility: hidden;
}

.categori-dropdown-active-large.open {
  opacity: 1;
  visibility: visible;
}

.categori-dropdown-active-small {
  z-index: 9;
  margin: 0;
  padding: 14px 0 23px;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  font-weight: 400;
  display: none;
  width: 100%;
}

.categori-dropdown-wrap ul li {
  display: block;
}

.categori-dropdown-wrap ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 34px;
  line-height: 1.5;
  color: #444;
  font-size: 14px;
}

.categori-dropdown-wrap ul li a:hover {
  color: #46a3bf;
  background-color: rgba(78, 151, 253, 0.1);
}

.categori-dropdown-wrap ul li a i {
  margin-right: 16px;
  font-size: 18px;
  opacity: 0.9;
}

.categori-dropdown-wrap ul li:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
  pointer-events: auto;
}

.categori-dropdown-wrap ul li.has-children {
  position: relative;
}

.categori-dropdown-wrap ul li.has-children > a::after {
  content: '\f105';
  font-family: "careem";
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -10px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu {
  left: 100%;
  top: 0;
  margin: 0;
  margin-top: 0px;
  border: 0;
  min-width: 800px;
  width: 100%;
  right: 0;
  -webkit-box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
          box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  border-radius: 0;
  padding: 20px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .submenu-title {
  font-size: 16px;
  font-weight: 500;
  display: block;
  color: #46a3bf;
  padding: 5px 34px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .header-banner2 {
  display: block;
  position: relative;
  margin-bottom: 15px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .header-banner2 .banne_info {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 15px;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .header-banner2 .banne_info a {
  text-transform: capitalize;
  position: relative;
  padding: 0;
  color: #272a2c !important;
}

.categori-dropdown-wrap ul li.has-children .dropdown-menu .header-banner2 .banne_info a::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  width: 50%;
  background-color: #272a2c;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.categori-dropdown-wrap .more_categories {
  border-top: 1px solid #eaeaea;
  padding: 10px 34px 0 34px;
  margin-top: 10px;
  color: #46a3bf;
  position: relative;
  font-size: 13px;
}

.categori-dropdown-wrap .more_categories::before {
  content: "";
  width: 15px;
  height: 1px;
  background-color: #46a3bf;
  position: absolute;
  right: 31px;
  top: 21px;
}

.categori-dropdown-wrap .more_categories::after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: #46a3bf;
  position: absolute;
  right: 38px;
  top: 14px;
}

.categori-dropdown-wrap .more_categories.show::after {
  display: none;
  content: none;
}

/*Pagination*/
.pagination-area .page-item {
  margin: 0 5px;
}

.pagination-area .page-item:first-child {
  margin-left: 0;
}

.pagination-area .page-item:first-child .page-link {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.pagination-area .page-item:last-child .page-link {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.pagination-area .page-item.active .page-link, .pagination-area .page-item:hover .page-link {
  color: #fff;
  background: #46a3bf;
}

.pagination-area .page-link {
  border: 0;
  padding: 0 10px;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  background: #f7f8f9;
  line-height: 40px;
  text-align: center;
}

.pagination-area .page-link.dot {
  background-color: transparent;
  color: #4f5d77;
  letter-spacing: 2px;
}

/*LAYOUT -> SIDEBAR*/
.widget-category {
  position: relative;
  padding: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.widget-category ul > li {
  padding: 6px 0;
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
}

.widget-category ul > li:hover {
  padding-left: 5px;
  -webkit-transition-duration: .2s;
          transition-duration: .2s;
}

.widget-category ul > li a {
  color: #242424;
}

.widget-category ul > li a:hover {
  color: #46a3bf;
}

/*Social network widget*/
.follow-us a {
  display: block;
  padding: 12px 10px;
  color: #fff;
  width: 50%;
  border-radius: 5px;
  font-size: 11px;
  overflow: hidden;
  height: 46px;
}

.follow-us a .social-count {
  font-weight: bold;
}

.follow-us a:hover i {
  -webkit-transform: translateY(-35px) !important;
  transform: translateY(-35px) !important;
}

.follow-us a i {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.2s cubic-bezier(0.65, 0.23, 0.31, 0.88);
  transition: 0.2s cubic-bezier(0.65, 0.23, 0.31, 0.88);
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
}

.follow-us a i.nth-2 {
  position: absolute;
  top: 38px;
  left: 0;
}

.follow-us i.v-align-space {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  padding-right: 4px;
  line-height: 1;
}

.follow-us .social-icon {
  display: inline-block;
  position: relative;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
}

.follow-us a.follow-us-facebook,
.single-social-share a.facebook-icon {
  background: #305c99;
}

.follow-us a.follow-us-twitter,
.single-social-share a.twitter-icon {
  background: #00cdff;
}

.follow-us a.follow-us-instagram,
.single-social-share a.instagram-icon {
  background: #3f729b;
}

.follow-us a.follow-us-youtube,
.single-social-share a.pinterest-icon {
  background: #e22b26;
}

.sidebar-widget .widget-header {
  border-bottom: 1px solid #eee;
}

.sidebar-widget .widget-header h5 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
}

.sidebar-widget.price_range, .sidebar-widget.product-sidebar {
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 30px;
}

.sidebar-widget .banner-img {
  float: none;
}

.widget_categories li.cat-item,
.widget_archive li,
.widget_pages li,
.widget_recent_comments li,
.widget_nav_menu li {
  text-align: right;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.15);
  display: table;
  width: 100%;
  font-size: 14px;
  padding: 7px 0;
}

.widget_categories {
  padding: 20px 20px 10px 20px;
  background-color: #f7f8f9;
  border-radius: 10px;
  border: 1px solid #eee;
}

.widget_categories li.cat-item:last-child {
  border: none;
}

.widget_categories li.cat-item a,
.widget_archive li a,
.widget_pages li a {
  text-align: left;
  float: left;
  padding: 0;
}

.newsletter h4, .newsletter h5 {
  color: #fff;
}

.widget_tags {
  position: relative;
  background-color: #f7f8f9;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 20px;
}

.widget_tags a {
  display: inline-block;
  background-color: #ffffff;
  border-radius: 26px;
  color: #242424;
  padding: 4px 15px 5px;
  margin: 0 8px 10px 0;
}

.newsletter .icon-email {
  max-width: 40px;
  margin-right: 15px;
}

.newsletter .form-subcriber input {
  border-radius: 50px 0 0 50px;
  border: 0;
  padding: 0 20px 0 30px;
  font-size: 14px;
}

.newsletter .form-subcriber button {
  border-radius: 0 50px 50px 0;
  border: 0;
  font-size: 14px;
  padding: 0 30px;
}

.download-app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 25px 0 33px;
}

.download-app a {
  display: block;
  margin-right: 12px;
}

.download-app a img {
  width: 170px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
}

.footer-list {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.footer-list li {
  display: block;
  margin: 0 0 13px;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.footer-list li:last-child {
  margin: 0;
}

.footer-list li:hover {
  padding-left: 5px;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.footer-list li a {
  font-size: 14px;
  color: #111111;
  display: block;
}

.footer-list li a:hover {
  color: #46a3bf;
}

.footer-bottom {
  border-top: 1px solid #eee;
}

#scrollUp {
  width: 40px;
  height: 40px;
  background-color: #46a3bf;
  color: #ffffff;
  right: 30px;
  bottom: 30px;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 999 !important;
}

#scrollUp i {
  display: block;
  line-height: 40px;
  font-size: 16px;
}

#scrollUp:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

/*LAYOUT -> SPACING**/
.section-padding-30 {
  padding: 30px 0;
}

.section-padding-50 {
  padding: 50px 0;
}

.section-padding-60 {
  padding: 60px 0;
}

.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-65 {
  padding: 65px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-155 {
  padding-top: 155px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pt-185 {
  padding-top: 185px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

.pt-195 {
  padding-top: 195px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

.pt-260 {
  padding-top: 260px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-155 {
  padding-bottom: 155px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

.pb-175 {
  padding-bottom: 175px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pb-185 {
  padding-bottom: 185px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

.pb-195 {
  padding-bottom: 195px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pl-125 {
  padding-left: 125px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.pl-135 {
  padding-left: 135px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.pl-145 {
  padding-left: 145px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pl-155 {
  padding-left: 155px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

.pl-165 {
  padding-left: 165px !important;
}

.pl-170 {
  padding-left: 170px !important;
}

.pl-175 {
  padding-left: 175px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

.pl-185 {
  padding-left: 185px !important;
}

.pl-190 {
  padding-left: 190px !important;
}

.pl-195 {
  padding-left: 195px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pr-125 {
  padding-right: 125px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.pr-135 {
  padding-right: 135px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pr-145 {
  padding-right: 145px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pr-155 {
  padding-right: 155px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

.pr-165 {
  padding-right: 165px !important;
}

.pr-170 {
  padding-right: 170px !important;
}

.pr-175 {
  padding-right: 175px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

.pr-185 {
  padding-right: 185px !important;
}

.pr-190 {
  padding-right: 190px !important;
}

.pr-195 {
  padding-right: 195px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

.plr-5-percent {
  padding: 0 5%;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-105 {
  margin-top: 105px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mt-135 {
  margin-top: 135px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mt-145 {
  margin-top: 145px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-155 {
  margin-top: 155px !important;
}

.mt-160 {
  margin-top: 160px !important;
}

.mt-165 {
  margin-top: 165px !important;
}

.mt-170 {
  margin-top: 170px !important;
}

.mt-175 {
  margin-top: 175px !important;
}

.mt-180 {
  margin-top: 180px !important;
}

.mt-185 {
  margin-top: 185px !important;
}

.mt-190 {
  margin-top: 190px !important;
}

.mt-195 {
  margin-top: 195px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-125 {
  margin-bottom: 125px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.mb-135 {
  margin-bottom: 135px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-145 {
  margin-bottom: 145px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-155 {
  margin-bottom: 155px !important;
}

.mb-160 {
  margin-bottom: 160px !important;
}

.mb-165 {
  margin-bottom: 165px !important;
}

.mb-170 {
  margin-bottom: 170px !important;
}

.mb-175 {
  margin-bottom: 175px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

.mb-185 {
  margin-bottom: 185px !important;
}

.mb-190 {
  margin-bottom: 190px !important;
}

.mb-195 {
  margin-bottom: 195px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-105 {
  margin-left: 105px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.ml-115 {
  margin-left: 115px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.ml-125 {
  margin-left: 125px !important;
}

.ml-130 {
  margin-left: 130px !important;
}

.ml-135 {
  margin-left: 135px !important;
}

.ml-140 {
  margin-left: 140px !important;
}

.ml-145 {
  margin-left: 145px !important;
}

.ml-150 {
  margin-left: 150px !important;
}

.ml-155 {
  margin-left: 155px !important;
}

.ml-160 {
  margin-left: 160px !important;
}

.ml-165 {
  margin-left: 165px !important;
}

.ml-170 {
  margin-left: 170px !important;
}

.ml-175 {
  margin-left: 175px !important;
}

.ml-180 {
  margin-left: 180px !important;
}

.ml-185 {
  margin-left: 185px !important;
}

.ml-190 {
  margin-left: 190px !important;
}

.ml-195 {
  margin-left: 195px !important;
}

.ml-200 {
  margin-left: 200px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mr-105 {
  margin-right: 105px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.mr-115 {
  margin-right: 115px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mr-125 {
  margin-right: 125px !important;
}

.mr-130 {
  margin-right: 130px !important;
}

.mr-135 {
  margin-right: 135px !important;
}

.mr-140 {
  margin-right: 140px !important;
}

.mr-145 {
  margin-right: 145px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mr-155 {
  margin-right: 155px !important;
}

.mr-160 {
  margin-right: 160px !important;
}

.mr-165 {
  margin-right: 165px !important;
}

.mr-170 {
  margin-right: 170px !important;
}

.mr-175 {
  margin-right: 175px !important;
}

.mr-180 {
  margin-right: 180px !important;
}

.mr-185 {
  margin-right: 185px !important;
}

.mr-190 {
  margin-right: 190px !important;
}

.mr-195 {
  margin-right: 195px !important;
}

.mr-200 {
  margin-right: 200px !important;
}

@media only screen and (max-width: 768px) {
  .logo.logo-width-1 a img {
    width: 120px;
  }
  .header-action .header-action-icon {
    margin-right: 15px;
  }
  .header-action .header-action-icon.header-action-mrg-none {
    margin-right: 15px;
  }
  .header-action .header-action-icon.header-action-mrg-none2 {
    margin-right: 15px;
  }
  .header-action .header-action-icon > a {
    font-size: 20px;
  }
  .header-action-2 .header-action-icon-2 {
    padding: 0 6px;
  }
  .header-action-2 .header-action-icon-2:last-child {
    padding: 0 0 0 6px;
  }
  .header-action-2 .header-action-icon-2 > a {
    color: #fff;
    fill: #fff;
    font-size: 22px;
    margin-right: 10px;
  }
  .cart-dropdown-wrap {
    width: 290px;
    right: -39px;
  }
  .cart-dropdown-wrap.cart-dropdown-hm2 {
    right: -63px;
  }
  .cart-dropdown-wrap ul li .shopping-cart-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    margin-right: 10px;
  }
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a {
    padding: 12px 22px 13px;
  }
  .main-categori-wrap > a {
    font-weight: 500;
  }
  .categori-dropdown-wrap ul li a {
    padding: 5px 12px;
  }
  .search-popup-wrap .search-popup-content form input {
    width: 270px;
    font-size: 25px;
    padding: 21px 20px 12px 0;
  }
  .mobile-header-wrapper-style {
    width: 300px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
    padding: 20px;
  }
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
    padding: 30px 20px;
  }
  .header-height-1 {
    min-height: 65px;
  }
  .header-height-2 {
    min-height: 65px;
  }
  .hero-slider-1 {
    height: 298px;
  }
  .hero-slider-content-2 h1 {
    font-size: 32px;
    line-height: 1.2;
  }
  .hero-slider-content-2 h2 {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .hero-slider-content-2 h4 {
    font-size: 16px;
    margin: 0 0 10px;
  }
  .hero-slider-content-2 .btn-default {
    color: #fff;
    background-color: #46a3bf;
    border-radius: 50px;
    padding: 10px 22px;
    font-size: 14px;
  }
  .hero-slider-content-2 p {
    font-size: 14px;
    line-height: 1.3;
  }
  .banner-big h4 {
    font-size: 12px;
  }
  .banner-big h2 {
    font-size: 16px;
  }
  .banner-big .btn {
    background: none;
    color: #fff;
    border-radius: 50px;
    font-size: 13px;
    padding: 0;
    border: 0;
    margin-top: -15px;
  }
  .home-slider .slider-arrow {
    display: none;
  }
  .home-slider .hero-slider-1.style-2 {
    height: 400px;
  }
  .home-slider .hero-slider-1.style-2 .hero-slider-content-2 {
    padding-left: 0;
    padding-top: 100px;
  }
  .home-slider .hero-slider-1.style-2 .single-slider-img img {
    max-width: 400px;
  }
  .home-slider .hero-slider-1.style-2 .slider-1-height-2 {
    height: 400px;
    position: relative;
  }
  .banner-img {
    float: left;
  }
  .banner-img.f-none {
    float: none;
  }
  .hero-slider-1.style-3 .slider-1-height-3 {
    height: 390px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 {
    padding-left: 20px;
  }
  .home-slide-cover .hero-slider-1.style-4 .hero-slider-content-2 {
    padding-left: 0;
  }
  .home-slide-cover .hero-slider-1.style-4 .hero-slider-content-2 h1 {
    font-size: 30px;
  }
  .home-slide-cover .hero-slider-1.style-4 .hero-slider-content-2 h2 {
    font-size: 14px;
  }
  .home-slide-cover .hero-slider-1.style-4 .hero-slider-content-2 h4 {
    font-size: 12px;
  }
  .header-style-3 .header-bottom-bg-color {
    background-color: #46a3bf;
  }
  .header-style-3 .main-nav {
    border: none !important;
  }
  .header-style-3 .sticky-bar.stick.sticky-blue-bg {
    background-color: #46a3bf;
  }
  .header-style-4 .header-bottom-bg-color {
    background-color: #46a3bf;
  }
  .header-style-4 .main-nav {
    border: none !important;
  }
  .header-style-4 .sticky-bar.stick.sticky-blue-bg {
    background-color: #46a3bf;
  }
  .font-xxl {
    font-size: 38px;
  }
}

/*Mobile landscape*/
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action .header-action-icon {
    margin-right: 20px;
  }
  .header-action .header-action-icon.header-action-mrg-none {
    margin-right: 20px;
  }
  .header-action .header-action-icon.header-action-mrg-none2 {
    margin-right: 20px;
  }
  .header-action .header-action-icon > a {
    font-size: 22px;
  }
  .header-action-2 .header-action-icon-2 {
    padding: 0 10px;
  }
  .header-action-2 .header-action-icon-2:last-child {
    padding: 0 0 0 10px;
  }
  .header-action-2 .header-action-icon-2 > a {
    font-size: 22px;
  }
  .cart-dropdown-wrap {
    width: 310px;
  }
  .search-popup-wrap .search-popup-content form input {
    width: 480px;
    font-size: 25px;
    padding: 21px 20px 12px 0;
  }
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
}

/*Tablet*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action .header-action-icon {
    margin-right: 33px;
  }
  .header-action .header-action-icon.header-action-mrg-none {
    margin-right: 33px;
  }
  .header-action .header-action-icon.header-action-mrg-none2 {
    margin-right: 33px;
  }
  .header-action-2 .header-action-icon-2 > a {
    color: #fff;
    fill: #fff;
  }
  .search-popup-wrap .search-popup-content form input {
    width: 650px;
    font-size: 35px;
  }
  .header-bottom {
    padding: 5px 0;
  }
  .header-height-1 {
    min-height: 50px;
  }
  .header-height-2 {
    min-height: 50px;
  }
  .header-height-3 {
    min-height: 50px;
  }
  .header-height-4 {
    min-height: 50px;
  }
  .header-style-5 .header-bottom {
    padding: 25px 0;
    border-bottom: 1px solid #f7f8f9;
  }
  .comment-form .email {
    padding-left: 0px;
  }
  .loop-grid.pr-30 {
    padding-right: 0 !important;
  }
}

/*Desktop*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo.logo-hm3 a img {
    width: 120px;
  }
  .main-menu.main-menu-mrg-1 {
    margin: 0 0 0 15px;
  }
  .main-menu > nav > ul > li ul.sub-menu li ul.level-menu.level-menu-modify {
    width: 165px;
  }
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content {
    left: 20px;
    top: 22px;
  }
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content h3 {
    font-size: 20px;
    line-height: 1.3;
    margin: 5px 0 7px;
  }
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price {
    margin: 0 0 10px;
  }
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-btn a {
    padding: 10px 16px 13px;
  }
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 8px;
  }
  .main-menu.main-menu-padding-1.hm3-menu-padding > nav > ul > li {
    padding: 0 6px;
  }
  .search-style-1 {
    margin-right: 15px;
  }
  .search-style-1 form {
    width: 170px;
  }
  .header-action.header-action-hm3 .header-action-icon {
    margin-right: 15px;
  }
  .header-action.header-action-hm3 .header-action-icon.header-action-mrg-none2 {
    margin-right: 0;
  }
  .header-action.header-action-hm3 .header-action-icon:last-child {
    margin-right: 0;
  }
  .search-style-2 form input {
    width: 340px;
  }
  .hotline p {
    font-size: 15px;
  }
  .search-popup-wrap .search-popup-content form input {
    width: 820px;
  }
  .header-height-1 {
    min-height: 157px;
  }
  .header-height-2 {
    min-height: 224px;
  }
  .header-height-3 {
    min-height: 133px;
  }
  .header-height-4 {
    min-height: 144px;
  }
}

/*Wide screen*/
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 14px;
  }
}

/*small phone*/
@media only screen and (max-width: 480px) {
  .carausel-6-columns, .carausel-4-columns {
    max-width: 375px;
    overflow: hidden;
  }
  .slider-arrow .slider-btn.slider-next {
    right: 0 !important;
  }
  .slider-arrow .slider-btn.slider-prev {
    left: 0 !important;
  }
  .hero-slider-1 {
    height: unset;
  }
  .hero-slider-content-2 {
    text-align: center;
    padding-top: 20px;
  }
  .hero-slider-content-2 p {
    width: 100%;
  }
  .header-height-2 {
    min-height: 40px;
  }
  .header-bottom-bg-color {
    padding: 10px 0 3px 0;
  }
  .banner-left-icon, .banner-img {
    margin-bottom: 15px;
  }
  .popular-categories .slider-btn.slider-prev {
    right: 50px !important;
    left: unset !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-4 {
    margin-bottom: 1rem;
  }
  .mb-sm-5 {
    margin-bottom: 2rem;
  }
  .heading-tab {
    display: block !important;
  }
  .heading-tab h3.section-title {
    margin-bottom: 15px !important;
  }
  .nav.right {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
  .hero-slider-1.style-2 .single-slider-img {
    display: none;
  }
  ul.footer-list {
    margin-bottom: 30px;
  }
  .section-padding-60 {
    padding: 30px 0;
  }
  .pt-60, .pt-40 {
    padding-top: 30px !important;
  }
  .pb-60 {
    padding-bottom: 30px !important;
  }
  .mb-60 {
    margin-bottom: 30px !important;
  }
  .hero-slider-1.style-3 .slider-1-height-3 {
    height: 220px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 {
    position: relative;
    text-align: left;
    padding-left: 15px;
    padding-top: 0;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 h1 {
    font-size: 18px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 h2 {
    font-size: 16px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 h4 {
    font-size: 14px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 p {
    font-size: 11px;
  }
  .hero-slider-1.style-3 .hero-slider-content-2 a.btn {
    display: none;
  }
  .header-style-5 {
    padding: 15px 0;
    border-bottom: 1px solid #f7f8f9;
  }
  .header-style-5 .search-style-2 {
    display: none;
  }
  .header-style-5 .header-action-2 .header-action-icon-2 > a {
    font-size: 18px;
  }
  .header-style-5 .sticky-bar.stick.sticky-white-bg {
    background-color: #fff;
    padding: 15px 0;
  }
  .font-xxl {
    font-size: 28px;
  }
  .w-50 {
    width: 100% !important;
  }
  .product-list .product-cart-wrap {
    display: block;
  }
  .product-list .product-cart-wrap .product-img-action-wrap {
    max-width: 100%;
  }
  .shop-product-fillter {
    display: block;
  }
  .shop-product-fillter .totall-product {
    margin-bottom: 15px;
  }
  .table td {
    display: block;
    width: 100%;
    text-align: center;
  }
  .table td::before {
    content: attr(data-title) " ";
    float: left;
    text-transform: capitalize;
    margin-right: 15px;
    font-weight: bold;
  }
  .table thead {
    display: none;
  }
  .loop-grid.pr-30 {
    padding-right: 0 !important;
  }
  .loop-grid.loop-list article {
    display: block;
  }
}
/*# sourceMappingURL=main.css.map */